import React, { useState, useEffect } from 'react';
import { loginUser } from '../../Data/data';

const Login = ({ tokenCallback }) => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [recordar, setRecordar] = useState(false);

    const [userNotFound, setUserNotFound] = useState(false);

    useEffect(() => {
        document.title = 'Desguaces - Login';
    });
    
    const handleRecordar = () => {
        setRecordar(!recordar);
    }

    const handleLoginSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            username,
            password
        });
        if (token !== 'Not found') {
            setUserNotFound(false);
            tokenCallback({ token: token, recordar: recordar });
        } else {
            setUserNotFound(true);
        }
    }

    const found = () => {
        if (userNotFound) {
            return (
                <div id="notfound" className="form-group mb-0 mt-4 text-center text-danger">
                    No se encontró al usuario/a.
                </div>
            );
        } else {
            return (
                null
            );
        }
    }

    return (
        <div className="main-wrapper">
            <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={{ backgroundImage: "url(/assets/images/background/login-register.jpg)", backgroundRepeat: 'no-repeat', backgroundPosition: 'center',  backgroundSize: "cover" }}>
                <div className="auth-box p-4 bg-white rounded">
                    <div id="loginform">
                        <div className="logo">
                            <h2 className="box-title mb-3 text-info text-center">Cont@Fact Desguaces</h2>
                            <h6 className="text-muted">Iniciar sesión</h6>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <form className="form-horizontal mt-3 form-material" id="loginform" action="#" onSubmit={handleLoginSubmit} method="post">
                                    <div className="form-group mb-3">
                                        <div className="">
                                            <input id="username" className="form-control" type="text" name="username" required="" placeholder="Nombre de usuario" value={username} onChange={e => setUserName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <div className="">
                                            <input id="password" className="form-control" type="password" name="password" required="" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex">
                                            <div className="checkbox checkbox-info pt-0">
                                                <input id="checkbox-signup" type="checkbox" className="material-inputs chk-col-indigo" onChange={handleRecordar} />
                                                <label htmlFor="checkbox-signup"> Recuérdame </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group text-center mt-4 mb-3">
                                        <div className="col-xs-12">
                                            <button className="btn btn-info d-block w-100 waves-effect waves-light" type="submit">Iniciar sesión</button>
                                        </div>
                                    </div>
                                    {found()}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;