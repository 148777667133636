import React from 'react';
import DataTable from 'react-data-table-component';
import { ArrowDown } from 'react-feather';

const sortIcon = <ArrowDown />;

const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

function DataTableBase(props) {
    return (
        <DataTable
            sortIcon={sortIcon}
            pagination
            responsive
            noDataComponent={"No se encontraron resultados"}
            paginationComponentOptions={paginationComponentOptions}
            persistTableHead
            {...props}
        />
    );
}

export default DataTableBase;