const apiUrl = 'https://api.desguaces.contafact.net/';
const conectorUrl = 'https://api.contafact.net/';

export function getApiUrl() {
    return apiUrl;
};

/**
 * Login
 */
export async function loginUser(credentials) {
    let url = apiUrl + 'auth/login';
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then((resp) => resp.json())
        .then((token) => {
            return token.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export async function logoutUser(nif) {
    let url = apiUrl + 'auth/logout';
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "nif": nif })
    })
        .catch((error) => {
            console.log(error);
        });
}

/**
 * Cambiar contraseña
 */
export async function changePass(credentials) {
    let url = apiUrl + 'auth/changePass';
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then((resp) => resp.json())
        .catch((error) => {
            console.log(error);
        });
}

/**
 * generate new api key
 */
export async function genApiKey(credentials) {
    let url = apiUrl + 'auth/genapikey';
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then((resp) => resp.json())
        .then((token) => {
            return token.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

/**
 * Check pass
 */
export async function checkPass(token, pass) {
    const fd = new FormData();
    fd.append("pass", pass);

    let apikey = token.api_key;
    let url = apiUrl + 'checkpass';
    return fetch(url, {
        method: 'POST',
        headers: {
            'X-Api-Key': apikey
        },
        body: fd
    })
        .then((resp) => resp.json())
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            console.log(error);
        });
}





/**
 * Logs
 */
 export async function getUserList(token) {
    let apikey = token.api_key;
    let url = apiUrl + "logs/users";
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            },
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getSessionLogs(token, desde, hasta) {
    const fd = new FormData();
    fd.append("desde", desde);
    fd.append("hasta", hasta);

    let apikey = token.api_key;
    let url = apiUrl + "logs/session";
    
    try {
        const resp = await fetch(url, {
            method: 'POST',
            headers: {
                'X-Api-Key': apikey
            },
            body: fd
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getVersionLogs(token) {
    let apikey = token.api_key;
    let url = apiUrl + "logs/version";
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            },
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}


/**
 * Marcas
 */
export async function getMarcas(token) {
    let apikey = token.api_key;
    let url = apiUrl + "marcas";
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getMarca(token, id) {
    let apikey = token.api_key;
    let url = apiUrl + "marcas/" + id;
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function guardarMarca(token, bodyData) {
    let apikey = token.api_key;
    let url = apiUrl + "marcas";
    try {
        fetch(url, {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Key': apikey,
            },
            body: bodyData,
        });
    } catch (error) {
        console.log(error);
    }
}

/**
 * Modelos
 */
export async function getModelos(token) {
    let apikey = token.api_key;
    let url = apiUrl + "modelos";
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getModelo(token, id) {
    let apikey = token.api_key;
    let url = apiUrl + "modelos/" + id;
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data.name;
    } catch (error) {
        console.log(error);
    }
}

export async function getModeloByMarca(token, id) {
    let apikey = token.api_key;
    let url = apiUrl + "modelos/marca/" + id;
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function guardarModelo(token, bodyData) {
    let apikey = token.api_key;
    let url = apiUrl + "modelos";
    try {
        fetch(url, {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Key': apikey,
            },
            body: bodyData,
        });
    } catch (error) {
        console.log(error);
    }
}

/**
 * Coches
 */
export async function altaCoche(token, bodyData) {
    let apikey = token.api_key;
    let url = apiUrl + "coches/alta";
    try {
        const resp = await fetch(url, {
            method: 'POST',
            headers: {
                'X-Api-Key': apikey
            },
            body: bodyData
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}


export async function uploadImg(token, bodyData) {
    let apikey = token.api_key;
    let url = apiUrl + "uploadimg";
    try {
        const resp = await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apikey,
            },
            body: bodyData,
        });
        const result = await resp.json();
        return result;
    } catch (error) {
        console.log(error);
    }
}


export async function getCoches(token) {
    let apikey = token.api_key;
    let url = apiUrl + "coches";
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getCoche(token, id) {
    let apikey = token.api_key;
    let url = apiUrl + "coches/coche/" + id;
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function deleteCoche(token, id) {
    let apikey = token.api_key;
    let url = apiUrl + "coches/ocultar/" + id;
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function updateLoc(token, bodyData) {
    let apikey = token.api_key;
    let url = apiUrl + "piezas/updateLoc";
    try {
        fetch(url, {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Key': apikey,
            },
            body: bodyData,
        });
    } catch (error) {
        console.log(error);
    }
}

export async function uploadArtImg(token, img, id, ref) {
    const formData = new FormData();
    formData.append('file', img);
    formData.append('idcoche', id);
    formData.append('ref', ref);

    if (typeof img === 'string') {
        formData.append('isBase64', 1);
    } else {
        formData.append('isBase64', 0);
    }

    let apikey = token.api_key;
    let url = apiUrl + "uploadArtImg";
    try {
        const res = await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apikey,
            },
            body: formData,
        }).then((res) => res.json());
        return res;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Despiece
 */
export async function altaDespiece(token, jsonStr) {
    let apikey = token.api_key;
    let url = apiUrl + "coches/despiece";
    fetch(url, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': apikey,
        },
        body: jsonStr,
    });
}


export async function getPiezas(token) {
    let apikey = token.api_key;
    let url = apiUrl + "piezas";
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getFamilias(token) {
    let apikey = token.api_key;
    let url = apiUrl + "piezas/familias";
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getDespieces(token) {
    let apikey = token.api_key;
    let url = apiUrl + "coches/despiece";
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getCocheDespiece(token, id) {
    let apikey = token.api_key;
    let url = apiUrl + "coches/despiece/" + id;
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apikey
            }
        });
        const json = await resp.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
}

export async function changeStatus(token, json) {
    let apikey = token.api_key;
    let url = apiUrl + "piezas/status";
    fetch(url, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': apikey,
        },
        body: json,
    });
}

// export conector
export async function conectorPiezas(token, json) {
    let apikey = token.api_key;
    let url = conectorUrl + "conector/piezas";
    fetch(url, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': apikey,
        },
        body: json,
    });
}