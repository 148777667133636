import React from 'react';
import { Search } from 'react-feather';

const SearchBar = ({ searchCallback }) => {
  const handleChange = (e) => {
    e.preventDefault();
    searchCallback(e.target.value);
  }

  return (
      <div className="form-floating">
        <input type="search" aria-label='Search' className="form-control" id="placeholder" placeholder="Buscador" onChange={handleChange} />
        <label><Search size={15} /> Buscador</label>
      </div>
  );
}

export default SearchBar;