import React, { useState, useEffect } from 'react';
import { Routes, Route, Outlet, Link, useLocation } from 'react-router-dom';
import { LogOut, Settings } from 'react-feather';
import { logoutUser, getVersionLogs } from './Data/data';

/**
 * Pages
 */
// login
import Login from './Pages/Login/Login';
// coches
import Coches from './Pages/Coches/Coches';
// artículos
import Articulos from './Pages/Articulos/Articulos';
// topnav
import BreadCrumbs from './Topnav/BreadCrumbs';
import SearchBar from './Topnav/SearchBar';
// offcanvas
import OffCanvasAlta from './Offcanvas/OffCanvasAlta';
import OffCanvasFilters from './Offcanvas/OffCanvasFilters';
import OffCanvasFiltersArt from './Offcanvas/OffCanvasFiltersArt';
// modal
import ModalDespiece from './Modal/ModalDespiece';
import ModalConfig from './Modal/ModalConfig';
import OffCanvasMarcas from './Offcanvas/OffCanvasMarcas';

// obtención de token de localstorage
const getToken = () => {
    const tokenString = localStorage.getItem('token');
    if (tokenString !== 'undefined') {
        const userToken = JSON.parse(tokenString);
        return userToken;
    } else {
        localStorage.clear();
        return null;
    }
};

// app principal
const App = () => {

    // hook token
    const [token, setToken] = useState(getToken());

    // hook filtros
    const [fData, setFData] = useState([]);
    const [fArtData, setFArtData] = useState([]);

    // hook buscador
    const [searchCb, setSearchCb] = useState('');

    // hook modal despiece
    const [modalCb, setModalCb] = useState(null);

    // recargar coches si hay cambios
    const [reloadCb, setReloadCb] = useState();

    // hooks cambio clave
    const [showCambioCLave, setShowCambioClave] = useState(false);

    // guarda el token en localstorage
    const saveToken = userToken => {
        if (userToken.recordar) {
            localStorage.setItem('token', JSON.stringify(userToken.token));
        }
        setToken(userToken.token);
    };

    // si no hay token, mostramos el login
    if (!token) {
        return <Login tokenCallback={saveToken} />;
    }

    // filtros de coches recibidos desde OffCanvasFilters -> Layout
    const receiveOffcanvas = (value) => {
        setFData(value);
    };

    // filtros de artículos recibidos desde OffCanvasFiltersArt -> Layout
    const receiveOffcanvasArt = (value) => {
        setFArtData(value);
    }

    // valor del buscador recibido desde SearchBar -> Layout
    const receiveSearchCallback = (value) => {
        setSearchCb(value);
    }

    // objeto coche recibido desde Coches -> Layout
    // abre modal despiece
    const receiveModalCallback = (value) => {
        let mainwrapper = document.getElementById('main-wrapper');
        mainwrapper.style.position = 'fixed';
        setModalCb(value);
        /*
        setModalCb(null);
        setModalCb(modalCb => [...modalCb, value]);
        */
    }

    // logout recibido desde Layout
    const logout = () => {
        logoutUser(token.xnif);
        setToken(null);
        setModalCb(null);
        localStorage.clear();
    }

    // para re-renderizar los coches si han habido altas o cambios en el despiece
    const receiveSaveChanges = () => {
        let mainwrapper = document.getElementById('main-wrapper');
        mainwrapper.style.position = '';
        setReloadCb(!reloadCb);
    }

    const receiveCambioClave = () => {
        setToken(null);
        setModalCb(null);
        localStorage.clear();
    }

    // al abrir modal config
    const receiveOpenModalCambioClave = () => {
        let mainwrapper = document.getElementById('main-wrapper');
        mainwrapper.style.position = 'fixed';
        setShowCambioClave(true);
    }

    // al cerrar modal config
    const receiveCambioClaveShow = () => {
        let mainwrapper = document.getElementById('main-wrapper');
        mainwrapper.style.position = '';
        setShowCambioClave(false);
    }

    return (
        <>
            <ModalConfig token={token} modalClaveCb={showCambioCLave} modalClaveShow={receiveCambioClaveShow} guardarClave={receiveCambioClave} />
            <ModalDespiece token={token} modalcb={modalCb} saveToParent={receiveSaveChanges} />
            <Routes>
                <Route path="/" element={<Layout tokenApp={token} filCar={receiveOffcanvas} filArt={receiveOffcanvasArt} searchCb={receiveSearchCallback} logoutCallback={logout} reloadLayout={receiveSaveChanges} showModalClave={receiveOpenModalCambioClave} />}>
                    <Route index element={<Coches tokenApp={token} filteredData={fData} searchData={searchCb} modalCallback={receiveModalCallback} reloadCb={reloadCb} />} />
                    <Route path="/coches" element={<Coches tokenApp={token} filteredData={fData} searchData={searchCb} modalCallback={receiveModalCallback} reloadCb={reloadCb} />} />
                    <Route path="/articulos" element={<Articulos token={token} search={searchCb} filteredData={fArtData} />} />
                    <Route path="*" element={<NoMatch token={token} />} />
                </Route>
            </Routes>
        </>
    );
}

function Layout({ tokenApp, filCar, filArt, searchCb, logoutCallback, reloadLayout, showModalClave }) {
    // última versión de la aplicación
    const [latestVer, setLatestVer] = useState('');

    // para mostrar filtros de coches o artículos dependiendo de la ruta
    const [switchFiltros, setSwitchFiltros] = useState('');

    // hook para abrir el modal de cambiar clave
    const [showCambioCLave, setShowCambioClave] = useState(false);

    // valores de los filtros desde Coches
    const filterCallback = (value) => {
        filCar(value);
    };

    // valores de los artículos desde Artículos
    const filterArtsCallback = (value) => {
        filArt(value);
    }

    // valor del buscador desde SearchBar
    const receiveSearchCallback = (value) => {
        searchCb(value);
    }

    // controlador del botón de logout
    const handleLogout = () => {
        logoutCallback(null);
    }

    //obtenemos la última versión de la app
    useEffect(() => {
        if (!tokenApp) {
            return;
        }
        getVersionLogs(tokenApp).then((ver) => {
            if (ver !== undefined) {
                setLatestVer(ver[0].version);
            }
        });
    }, [tokenApp]);

    // mostramos filtros de coches o artículos dependiendo de la ruta
    let location = useLocation();
    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/coches') {
            setSwitchFiltros(true);
        } else {
            setSwitchFiltros(false);
        }
    }, [location]);

    const handleOpenCambioClave = () => {
        setShowCambioClave(!showCambioCLave);
        showModalClave(showCambioCLave);
    }

    // cambia el estilo de los botones coches/artículos
    function ActiveCat() {
        if (location.pathname === '' || location.pathname === '/' || location.pathname === '/coches') {
            return (
                <div className="ms-3 navbar-nav d-flex align-items-center" data-navbarbg="skin1">
                    <Link className="btn btn-success waves-effect waves-dark" to={`coches`}>
                        <i className="mdi mdi-car"></i>
                        <span className="hide-menu"> Coches</span>
                    </Link>
                    <Link className="ms-2 btn waves-effect waves-dark text-white" to={`articulos`} aria-expanded="false">
                        <i className="mdi mdi-car-battery"></i>
                        <span className="hide-menu"> Artículos</span>
                    </Link>
                </div>
            );
        } else {
            return (
                <div className="ms-3 navbar-nav d-flex align-items-center" data-navbarbg="skin1">
                    <Link className="btn waves-effect waves-dark text-white" to={`coches`}>
                        <i className="mdi mdi-car"></i>
                        <span className="hide-menu"> Coches</span>
                    </Link>
                    <Link className="ms-2 btn btn-success waves-effect waves-dark" to={`articulos`} aria-expanded="false">
                        <i className="mdi mdi-car-battery"></i>
                        <span className="hide-menu"> Artículos</span>
                    </Link>
                </div>
            );
        }
    }

    return (
        <div id="main-wrapper" data-theme="light" data-layout="none" data-navbarbg="skin1" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full" className="">
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/">
                            <span className="logo-text">
                                Cont@Fact Desguaces - v{latestVer}
                            </span>
                        </a>
                    </div>
                    <div className="navbar-collapse collapse navbar-header" id="navbarSupportedContent" data-navbarbg="skin1">
                        {ActiveCat()}
                        <ul className="navbar-nav ms-auto d-flex align-items-center">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle waves-effect waves-dark" href="#" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <h3 type="text" className="text-white text-center pt-2" style={{ cursor: 'pointer' }} id="prenametext">{tokenApp.xname} </h3><h6 className="text-white text-center">{tokenApp.xempresa}</h6>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end user-dd animated flipInY">
                                    <div className="d-flex no-block align-items-center p-3 bg-info text-white mb-2">
                                        <div className="ms-2">
                                            <h4 className="mb-0 text-white">{tokenApp.xname}</h4>
                                        </div>
                                    </div>
                                    <a className="dropdown-item" href="#" style={{ lineHeight: '10px', cursor: 'pointer' }} onClick={handleOpenCambioClave}><Settings className="text-warning me-2" size={20} />
                                        Configuración</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" style={{ lineHeight: '10px', cursor: 'pointer' }} onClick={handleLogout}><LogOut className="feather-sm text-danger me-1 ms-1" />Cerrar sesión</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>

            <div className="page-wrapper" style={{ display: 'block' }}>
                <div className='row page-titles pt-0'>
                    <BreadCrumbs path={location} />
                    <>
                        <div className="pt-3 col-md-6 align-self-center">
                            <SearchBar searchCallback={receiveSearchCallback} />
                        </div>

                        <div className="pt-3 col-md-3 align-self-center d-md-block">
                            <div className="d-flex mt-2 justify-content-end">
                                <div className="d-flex me-3 ms-2">
                                    <OffCanvasMarcas tokenLayout={tokenApp} saveToParent={reloadLayout} />
                                </div>
                                <div className="d-flex me-3 ms-2">
                                    <OffCanvasAlta tokenLayout={tokenApp} saveToParent={reloadLayout} />
                                </div>
                                <div className="d-flex ms-2">
                                    {switchFiltros ? <OffCanvasFilters tokenLayout={tokenApp} carData={filterCallback} /> : <OffCanvasFiltersArt tokenLayout={tokenApp} artData={filterArtsCallback} />}
                                </div>
                            </div>
                        </div>
                    </>
                </div>
                <div className="container-fluid" style={{ "overflowY": "auto" }}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>¡Nada que ver aquí!</h2>
            <p>
                <Link to="/">Ir a la página de inicio</Link>
            </p>
        </div>
    );
}

export default App;