import React, { useEffect, useState, useRef } from 'react';
import { getMarcas, getModeloByMarca, guardarMarca, guardarModelo } from '../Data/data';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
//const WebcamComponent = () => <Webcam />;

const OffCanvasMarcas = ({ tokenLayout, saveToParent }) => {
    // form select options
    const [optMarcas, setOptMarcas] = useState([]);
    const [optModelosId, setOptModelosId] = useState([]);
    const [optModelos, setOptModelos] = useState([]);
    const [disabledModelos, setDisabledModelos] = useState(true);

    const [selMarca, setSelMarca] = useState(null);

    // typeahead value
    const [marca, setMarca] = useState('');
    const [modeloId, setModeloId] = useState('');
    const [modelo, setModelo] = useState('');

    const refMarca = useRef();
    const refIdModelo = useRef();
    const refModelo = useRef();

    // flags para bloquear los botones de guardado
    const [marcaFlag, setMarcaFlag] = useState(false);
    const [idFlag, setIdFlag] = useState(false);
    const [modeloFlag, setModeloFlag] = useState(false);

    let marcaInput = '';
    let modeloIdInput = '';
    let modeloInput = '';

    // submit button
    const [disableGuardarMarcas, setDisableGuardarMarcas] = useState(true);
    const [disableGuardarModelos, setDisableGuardarModelos] = useState(true);

    // fetch marcas
    useEffect(() => {
        getMarcas(tokenLayout).then((marcas) => {
            if (marcas !== undefined) {
                let newArr = [];
                marcas.forEach(marca => {
                    newArr.push({ value: marca.id, label: marca.label });
                });
                setOptMarcas(newArr);
            }
        });
    }, []);


    /**
     * Nueva marca
     */
    const handleInputChangeMarca = (e) => {
        if (e === '') {
            setMarcaFlag(false);
            return;
        }
        marcaInput = e;
        setMarca(e);
        const found = optMarcas.find(x => x.label.toLowerCase() === e.toLowerCase());
        if (found) {
            setMarcaFlag(false);
        } else {
            setMarcaFlag(true);
        }
    }
    const handleChangeMarca = (e) => {
        if (marcaInput === '') {
            setMarcaFlag(false);
            return;
        }

        if (marca === undefined) {
            setMarcaFlag(false);
            return;
        }

        if (e.length > 0) {
            setMarcaFlag(false);
            
        } else {
            setMarcaFlag(true);
        }
    }

    useEffect(() => {
        if (marcaFlag) {
            setDisableGuardarMarcas(false);
        } else {
            setDisableGuardarMarcas(true);
        }
    }, [marcaFlag]);

    // select marcas
    const handleSelectMarcas = (e) => {
        if (e !== null) {
            try {
                setSelMarca(e);
                getModeloByMarca(tokenLayout, e.value).then((modelos) => {
                    if (modelos !== undefined) {

                        let newArr = [], newArrIds = [];
                        modelos.forEach(modelo => {
                            newArr.push({ value: modelo.id, label: modelo.name });
                            newArrIds.push({ value: modelo.id, label: modelo.modelid });
                        });
                        setOptModelos(newArr); // typeahead name modelos
                        setOptModelosId(newArrIds); // typeahead modelid modelos

                        setDisabledModelos(false);
                    } else {
                        setDisabledModelos(true);
                    }
                });
            } catch (error) {
                setSelMarca([]);
            }
        } else {
            setDisabledModelos(true);
            setSelMarca(null);
        }
    }



    /**
     * Nuevo Modelo ID
     */
    const handleInputChangeModeloId = (e) => {
        if (e === '') {
            setIdFlag(false);
            return;
        }
        modeloIdInput = e;
        setModeloId(e);
        const found = optModelosId.find(x => x.label.toLowerCase() === e.toLowerCase());
        if (found) {
            setIdFlag(false);
        } else {
            setIdFlag(true);
        }
    }

    const handleChangeModeloId = (e) => {
        if (modeloIdInput === '') {
            setIdFlag(false);
            return;
        }

        if (modeloIdInput === undefined) {
            setIdFlag(false);
            return;
        }

        if (e.length > 0) {
            setIdFlag(false);
        } else {
            setIdFlag(true);
        }
    }


    /**
     * Nuevo Modelo name
     */
    const handleInputChangeModelo = (e) => {
        if (e === '') {
            setModeloFlag(false);
            return;
        }
        modeloInput = e;
        setModelo(e);
        const found = optModelos.find(x => x.label.toLowerCase() === e.toLowerCase());
        if (found) {
            setModeloFlag(false);
        } else {
            setModeloFlag(true);
        }
    }

    const handleChangeModelo = (e) => {
        if (modeloInput === '') {
            setModeloFlag(false);
            return;
        }

        if (modeloInput === undefined) {
            setModeloFlag(false);
            return;
        }

        if (e.length > 0) {
            setModeloFlag(false);
        } else {
            setModeloFlag(true);
        }
    }

    // si los flags son true es que hay un id de modelo y nombre de modelo correctos
    // desactiva o no el botón de guardar modelo
    useEffect(() => {
        if (idFlag && modeloFlag) {
            setDisableGuardarModelos(false);
        } else {
            setDisableGuardarModelos(true);
        }
    }, [idFlag, modeloFlag]);


    // botón guardar marca
    const handleGuardarMarca = () => {
        let bodyData = JSON.stringify({
            label: marca
        });
        guardarMarca(tokenLayout, bodyData);

        setMarcaFlag(false);
        setMarca('');
        refMarca.current?.clear();
        saveToParent(true);

    }

    // botón guardar modelo
    const handleGuardarModelo = () => {
        let bodyData = JSON.stringify({
            idmarca: selMarca.value,
            modelid: modeloId,
            name: modelo
        });
        guardarModelo(tokenLayout, bodyData);

        setIdFlag(false);
        setModeloFlag(false);
        setModeloId('');
        setModelo('');
        refIdModelo.current?.clear();
        refModelo.current?.clear();
        saveToParent(true);
    }

    return (
        <>
            <Button variant="success" className="btn btn-sm btn-primary text-white me-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDetalles" role="button" id="btnTrigger" aria-controls="offcanvasNav">
                <i className="mdi mdi-plus-circle-outline">Agregar detalles</i>
            </Button>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasDetalles" data-bs-scroll="true" aria-labelledby="offcanvasNavLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasNavLabel">Agregar marcas y modelos</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" tabIndex="-1"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-1">
                                <label>Agregar una marca nueva:</label>
                                <Typeahead
                                    style={{ margin: 0 }}
                                    id={'typeaheadMarca'}
                                    onInputChange={handleInputChangeMarca}
                                    onChange={handleChangeMarca}
                                    options={optMarcas}
                                    value={marca}
                                    ref={refMarca}
                                />

                                <button className="btn btn-sm btn-primary mt-1" onClick={() => handleGuardarMarca()} disabled={disableGuardarMarcas}>Guardar marca</button>
                            </div>
                            <div className="mt-3">
                                <label>Agregar un modelo a la marca seleccionada:</label>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-1">
                                            <Select options={optMarcas} isClearable='true' onChange={handleSelectMarcas} value={selMarca} />
                                        </div>
                                        <div className="mb-1">
                                            <label>ID del Modelo</label>
                                            <Typeahead
                                                style={{ margin: 0 }}
                                                id={'typeaheadModeloId'}
                                                onInputChange={handleInputChangeModeloId}
                                                onChange={handleChangeModeloId}
                                                options={optModelosId}
                                                value={modeloId}
                                                disabled={disabledModelos}
                                                ref={refIdModelo}
                                            />
                                        </div>
                                        <div className="mb-1" style={{ margin: 0 }}>
                                            <label>Nombre</label>
                                            <Typeahead
                                                style={{ margin: 0 }}
                                                id={'typeaheadModelo'}
                                                onInputChange={handleInputChangeModelo}
                                                onChange={handleChangeModelo}
                                                options={optModelos}
                                                value={modelo}
                                                disabled={disabledModelos}
                                                ref={refModelo}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-sm btn-primary mt-1" onClick={() => handleGuardarModelo()} disabled={disableGuardarModelos}>Guardar modelo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OffCanvasMarcas;