import React, { useEffect, useState, useRef, forwardRef, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { getFamilias, getPiezas, getCocheDespiece, altaDespiece, changeStatus, conectorPiezas, updateLoc, getApiUrl, getCoche, deleteCoche } from '../Data/data';
import { Trash, Edit2, AlertCircle, Paperclip, Camera, CameraOff, Check } from 'react-feather';
import Webcam from "react-webcam";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Compressor from 'compressorjs';
import './apidoc.css';
const MySwal = withReactContent(Swal);

const ModalDespiece = ({ token, modalcb, saveToParent }) => {
    const [selCar, setSelCar] = useState({});
    const [selCarImg, setSelCarImg] = useState();

    // modal hooks
    const [show, setShow] = useState(false);

    const handleClose = () => {
        let mainwrapper = document.getElementById('main-wrapper');
        mainwrapper.style.position = '';
        setShow(false);
        setImgsArts([]);
        setEditImgsArts([]);
        setCocheImageBlob(null);
        document.title = 'Desguaces - Coches';
    }

    // piezas
    const [optFamilia, setOptFamilia] = useState([]);
    const [listaArticulos, setListaArticulos] = useState([]);
    const [optArticulos, setOptArticulos] = useState([]);
    const [selFamilia, setSelFamilia] = useState(null);
    const [estadoArticulos, setEstadoArticulos] = useState([]);
    const [cocheDespiece, setCocheDespiece] = useState([]);

    // table ref
    const tableRef = useRef([]);

    // loc generales
    const [naveGen, setNaveGen] = useState('');
    const [filaGen, setFilaGen] = useState('');
    const [estanteGen, setEstanteGen] = useState('');
    const [profGen, setProfGen] = useState('');

    // loc form
    const [naves, setNaves] = useState([]);
    const [filas, setFilas] = useState([]);
    const [estantes, setEstantes] = useState([]);
    const [profs, setProfs] = useState([]);

    // loc change trigger
    const [trigger, setTrigger] = useState(false);

    // toggle artículos guardados
    const [arrTog, setArrTog] = useState([]);

    // loading artículos guardados
    const [loading, setLoading] = useState();

    // buscador artículos
    const [searchInput, setSearchInput] = useState('');

    // selec img
    const [imgError, setImgError] = useState(false);
    const [image, setImage] = useState('');
    const [imgsArts, setImgsArts] = useState([]);
    const [selectedArtImg, setSelectedArtImg] = useState('');
    const [imgSizeError, setImgSizeError] = useState(false);
    const [imgFormatError, setImgFormatError] = useState(false);

    // edit article img
    const [editImgError, setEditImgError] = useState(false);
    const [editImage, setEditImage] = useState('');
    const [editImgsArts, setEditImgsArts] = useState([]);
    const [selectedEditArtImg, setSelectedEditArtImg] = useState('');
    const [imgEditSizeError, setEditImgSizeError] = useState(false);
    const [imgEditFormatError, setEditImgFormatError] = useState(false);

    // image/camera
    const webcamRef = useRef(null);
    // toggle camara
    const [camSel, setCamSel] = useState('');

    const [errorCam, setErrorCam] = useState();
    const [showCam, setShowCam] = useState(false);

    const fileRef = useRef([]);
    const editFileRef = useRef([]);

    const cocheImgRef = useRef(null);

    // selec img coche
    const [cocheImage, setCocheImage] = useState('');
    const [cocheImageBlob, setCocheImageBlob] = useState(null);
    const [cocheImgSizeError, setCocheImgSizeError] = useState(false);
    const [cocheImgFormatError, setCocheImgFormatError] = useState(false);

    useEffect(() => {
        fileRef.current = fileRef.current.slice(0, estadoArticulos.length);
    }, [estadoArticulos]);

    /**
     * Use effect con el objeto del coche clickado en el grid
     */
    useEffect(() => {
        if (!token) {
            return;
        }
        if (modalcb !== undefined && modalcb !== null) {
            document.title = 'Desguaces - Despiece ' + modalcb.matricula;

            // spinner en movimiento mientras se carguen los datos
            setLoading(true);

            // reinicialización
            setCocheDespiece([]);
            setOptArticulos([]);
            setSelFamilia(null);
            setEstadoArticulos([]);
            setNaves([]);
            setFilas([]);
            setEstantes([]);
            setProfs([]);
            setEditImgsArts([]);
            setShowCam(false);

            // data (si tiene) de despiece del coche
            getCocheDespiece(token, modalcb.id).then((despiece) => {
                if (despiece !== undefined) {
                    setCocheDespiece(despiece);

                    despiece.forEach(el => {
                        setEditImgsArts(editImgsArts => [...editImgsArts, { id: el.id, ref: el.ref, blob: '', img: '' }]);
                    });
                    let toggles = [];
                    despiece.forEach(el => {
                        let obj = { id: el.id, loc: el.loc, imgerror: false, toggle: false, disabled: false };
                        toggles.push(obj);
                    });
                    setArrTog(toggles);
                }
            });

            // guardamos el coche seleccionado
            setSelCar(modalcb);

            // ruta de la img
            /*
            if (modalcb.imagePath !== "") {
                setSelCarImg(modalcb.imagePath);
            } else {
                getCoche(token, modalcb.id).then((coche) => {
                    if (coche !== undefined) {
                        setSelCar(coche[0]);
                        setSelCarImg(coche[0].imagePath);
                    }
                }).finally(() => {
                });
            }
            */
            // al seleccionar un coche, comprobamos sus datos
            getCoche(token, modalcb.id).then((coche) => {
                if (coche !== undefined) {
                    setSelCar(coche[0]);
                    setSelCarImg(coche[0].imagePath);
                }
            }).finally(() => {
            });

            // mostramos el modal
            setShow(true);

            // obtenemos las familias y las piezas para los selectores
            getFamilias(token).then((familias) => {
                if (familias !== undefined) {
                    let newArr = [];
                    familias.forEach(familia => {
                        newArr.push({ value: familia.id, label: familia.familia })
                    });
                    setOptFamilia(newArr);
                }
            });

            getPiezas(token).then((piezas) => {
                if (piezas !== undefined) {
                    setListaArticulos(piezas);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [token, modalcb]);

    // en caso de pulsar sobre una familia, buscamos sus artículos
    useEffect(() => {
        let selArticulos = [];
        if (selFamilia !== null) {
            getPiezas(token).then((piezas) => {
                if (piezas !== undefined) {
                    piezas.forEach(pieza => {
                        if (pieza.familia === selFamilia.label) {
                            selArticulos.push(pieza);
                        }
                    });
                    setOptArticulos(selArticulos);
                }
            });
        } else {
            setOptArticulos([]);
        }
    }, [selFamilia]);

    /**
     *  camera
     */
    const handleError = (error) => {
        setErrorCam(error);
    }

    const capture = useCallback((comp) => {
        if (comp === 1) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc);
            setShowCam(!showCam);
        } else {
            const imageSrc = webcamRef.current.getScreenshot();
            setEditImage(imageSrc);
            setShowCam(!showCam);
        }
    }, [webcamRef]);

    const videoConstraints = {
        facingMode: { exact: "environment" }
    };

    /**
     * Naves
     */
    const handleChangeNave = (e, id) => {
        const objs = naves;
        let found = objs.find(el => el.id === id);
        if (found) {
            const idx = objs.indexOf(found);
            objs[idx].value = e.target.value;
            setNaves(objs);
            setTrigger(!trigger);
        }
    }
    const valueNave = (id) => {
        let found = naves.find(el => el.id === id);
        return found.value;
    }

    /**
     * Filas
     */
    const handleChangeFilas = (e, id) => {
        const objs = filas;
        let found = objs.find(el => el.id === id);
        if (found) {
            const idx = objs.indexOf(found);
            objs[idx].value = e.target.value;
            setFilas(objs);
            setTrigger(!trigger);
        }
    }
    const valueFila = (id) => {
        let found = filas.find(el => el.id === id);
        return found.value;
    }

    /**
     * Estantes
     */
    const handleChangeEstantes = (e, id) => {
        const objs = estantes;
        let found = objs.find(el => el.id === id);
        if (found) {
            const idx = objs.indexOf(found);
            objs[idx].value = e.target.value;
            setEstantes(objs);
            setTrigger(!trigger);
        }
    }
    const valueEstante = (id) => {
        let found = estantes.find(el => el.id === id);
        return found.value;
    }

    /**
     * Profundidades
     */
    const handleChangeProfs = (e, id) => {
        const objs = profs;
        let found = objs.find(el => el.id === id);
        if (found) {
            const idx = objs.indexOf(found);
            objs[idx].value = e.target.value;
            setProfs(objs);
            setTrigger(!trigger);
        }
    }
    const valueProf = (id) => {
        let found = profs.find(el => el.id === id);
        return found.value;
    }


    /**
     * Handles
     */
    // select familia
    const handleFamilia = (e) => {
        setSelFamilia(e);
    }

    // checkboxes artículos
    // nos servirá para controlar el estado del artículo, si lo hemos añadido (artículos modificados)
    // o si lo hemos borrado
    const handleEstado = (id) => {
        let isInArray = estadoArticulos.find(el => el.id === id);
        if (isInArray) {
            const index = estadoArticulos.indexOf(isInArray);
            if (index > -1) {
                let newArr = estadoArticulos.slice();
                newArr.splice(index, 1);
                setEstadoArticulos(newArr);

                setFormLoc(naves, id, 'n');
                setFormLoc(filas, id, 'f');
                setFormLoc(estantes, id, 'e');
                setFormLoc(profs, id, 'p');

                let newImgsArts = imgsArts.slice();
                newImgsArts.splice(index, 1);
                setImgsArts(newImgsArts);
            }
        } else {
            let found = optArticulos.find(el => el.id === id);
            setEstadoArticulos(selArticulos => [...selArticulos, found]);

            setNaves(naves => [...naves, { id: id, value: naveGen }]);
            setFilas(filas => [...filas, { id: id, value: filaGen }]);
            setEstantes(estantes => [...estantes, { id: id, value: estanteGen }]);
            setProfs(profs => [...profs, { id: id, value: profGen }]);

            setImgsArts(imgsArts => [...imgsArts, { id: id, blob: '', img: '' }]);
        }
    }

    // previsualización de imagenes en artículos seleccionados
    const imgPreviewArts = (id) => {
        let found = imgsArts.find(el => el.id === id);
        if (found) {
            return found.blob;
        }
        return '';
    }

    // previsualización de imagenes en artículos ya guardados
    const imgEditPreviewArts = (id) => {
        let found = editImgsArts.find(el => el.id === id);
        if (found) {
            return found.blob;
        }
        return '';
    }

    const handleSelecImg = (e, i) => {
        setImgError(false);
        setSelectedArtImg(e.currentTarget.value);
        fileRef.current[i].click();
    }

    // al seleccionar archivo en artículos seleccionados
    const onFileChange = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        let imgData = e.target.files[0];
        let imgType = e.target.files[0].type;
        let typeSplit = imgType.split('/');

        if (typeSplit[1] !== 'jpg' && typeSplit[1] !== 'jpeg' && typeSplit[1] !== 'png' && typeSplit[1] !== 'gif' && typeSplit[1] !== 'jfif') {
            setImgFormatError(true);
        } else {
            new Compressor(imgData, {
                strict: true,
                checkOrientation: true,
                minWidth: 0,
                minHeight: 0,
                height: 720,
                resize: 'none',
                quality: 0.4,
                mimeType: 'auto',
                convertTypes: 'image/jpg,image/jpeg,image/png,image/gif,image/jfif',
                convertSize: 7000,
                success(result) {
                    let resultType = result.type;
                    let resultTypeSplit = resultType.split('/');
                    let imgSize = result.size;

                    let imgerror = false;

                    if (resultTypeSplit[0] !== 'image') {
                        imgerror = true;
                        setImgFormatError(true);
                    }

                    if (resultTypeSplit[1] !== 'jpg' && resultTypeSplit[1] !== 'jpeg' && resultTypeSplit[1] !== 'png' && resultTypeSplit[1] !== 'gif' && resultTypeSplit[1] !== 'jfif') {
                        imgerror = true;
                        setImgFormatError(true);
                    }

                    if (imgSize > 700000) {
                        imgerror = true;
                        setImgSizeError(true);
                    }

                    if (!imgerror) {
                        setImgError(false);
                        setImgFormatError(false);
                        setImgSizeError(false);
                        var file = new File([result], result.name);
                        setImage(file);
                    }
                },
                error(err) {
                    console.log(err.message);
                },
            });
        }
    }

    // si hay cambios en alguna imagen, recorremos la array de artículos
    // en cada artículo guardamos una preview y la img a subir
    useEffect(() => {
        if (!image) {
            return;
        }
        let isInArray = imgsArts.find(el => el.id === selectedArtImg);
        let newArrImgs = imgsArts.slice();
        if (isInArray) {
            const index = newArrImgs.indexOf(isInArray);
            setShowCam(!showCam);
            if (index > -1) {
                if (typeof image === 'string') {
                    newArrImgs[index].blob = image;
                    newArrImgs[index].img = image;
                } else {
                    const objectUrl = URL.createObjectURL(image);
                    newArrImgs[index].blob = objectUrl;
                    newArrImgs[index].img = image;
                }
                setImgsArts(newArrImgs);
            }
        }
    }, [image]);

    const handleSelecEditImg = (e, i) => {
        setEditImgError(false);
        setSelectedEditArtImg(e.currentTarget.value);
        editFileRef.current[i].click();
    }

    // al seleccionar un archivo en artículos ya guardados
    const onFileEditChange = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        let imgData = e.target.files[0];
        let imgType = e.target.files[0].type;
        let typeSplit = imgType.split('/');

        if (typeSplit[1] !== 'jpg' && typeSplit[1] !== 'jpeg' && typeSplit[1] !== 'png' && typeSplit[1] !== 'gif' && typeSplit[1] !== 'jfif') {
            setEditImgFormatError(true);
        } else {
            new Compressor(imgData, {
                strict: true,
                checkOrientation: true,
                minWidth: 0,
                minHeight: 0,
                height: 720,
                resize: 'none',
                quality: 0.4,
                mimeType: 'auto',
                convertTypes: 'image/jpg,image/jpeg,image/png,image/gif,image/jfif',
                convertSize: 7000,
                success(result) {
                    let resultType = result.type;
                    let resultTypeSplit = resultType.split('/');
                    let imgSize = result.size;

                    let imgerror = false;

                    if (resultTypeSplit[0] !== 'image') {
                        imgerror = true;
                        setEditImgError(true);
                        setEditImgFormatError(true);
                    }

                    if (resultTypeSplit[1] !== 'jpg' && resultTypeSplit[1] !== 'jpeg' && resultTypeSplit[1] !== 'png' && resultTypeSplit[1] !== 'gif' && resultTypeSplit[1] !== 'jfif') {
                        imgerror = true;
                        setEditImgError(true);
                        setEditImgFormatError(true);
                    }

                    if (imgSize > 700000) {
                        imgerror = true;
                        setEditImgError(true);
                        setEditImgSizeError(true);
                    }

                    if (!imgerror) {
                        setEditImgFormatError(false);
                        setEditImgSizeError(false);
                        setEditImgError(false);
                        var file = new File([result], result.name);
                        setEditImage(file);
                    }
                },
                error(err) {
                    console.log(err.message);
                },
            });
        }

        /*
        let imgData = e.target.files[0];
        let imgType = e.target.files[0].type;
        let typeSplit = imgType.split('/');
        let imgSize = e.target.files[0].size;
        if (typeSplit[0] === 'image' && imgSize < 700000) {
            setEditImgError(false);
            setEditImage(imgData);
        } else {
            setEditImgError(true);
        }*/

    }

    const handleShowCam = (id) => {
        setCamSel(id);
        setSelectedArtImg(id);
        setSelectedEditArtImg(id);
        setShowCam(!showCam);
    }

    // si hay cambios al editar una imagen de los artículos guardados
    // recorremos la array y asignamos una preview y la imagen a subir
    useEffect(() => {
        if (!editImage) {
            return;
        }
        let isInArray = editImgsArts.find(el => el.id === selectedEditArtImg);
        let newArrImgs = editImgsArts.slice();
        if (isInArray) {
            const index = newArrImgs.indexOf(isInArray);
            if (index > -1) {
                if (typeof editImage === 'string') {
                    newArrImgs[index].blob = editImage;
                    newArrImgs[index].img = editImage;
                } else {
                    const objectUrl = URL.createObjectURL(editImage);
                    newArrImgs[index].blob = objectUrl;
                    newArrImgs[index].img = editImage;
                }
                setEditImgsArts(newArrImgs);
            }
        }
    }, [editImage]);

    // search bar
    const onFormSearchSubmit = (e) => {
        e.preventDefault();
        regexSearch();
    }

    const handleClickBuscar = () => {
        regexSearch();
    }

    function regexSearch() {
        if (searchInput !== '') {
            let strSearch = searchInput.toLowerCase();
            strSearch = strSearch.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
            let splitStr = strSearch.split(' ');
            let regexStr = '';
            for (let i = 0; i < splitStr.length; i++) {
                regexStr += "(?=.*" + splitStr[i] + ")";
            }
            regexStr += '.+';
            let re = new RegExp(regexStr, 'g');
            let arrMatches = [];
            listaArticulos.forEach(art => {
                let str = art.articulo.toLowerCase();
                while (!!(re.exec(str))) {
                    arrMatches.push(art);
                }
            });
            setOptArticulos(arrMatches);
        } else {
            setOptArticulos([]);
        }
    }

    //Parámetros generales
    const handleGeneral = (e) => {
        e.preventDefault();

        let newNaves = naves;
        newNaves.forEach(el => {
            el.value = naveGen;
        });
        setNaves(newNaves);

        let newFilas = filas;
        newFilas.forEach(el => {
            el.value = filaGen;
        });
        setFilas(newFilas);

        let newEstantes = estantes;
        newEstantes.forEach(el => {
            el.value = estanteGen;
        });
        setEstantes(newEstantes);

        let newProfs = profs;
        newProfs.forEach(el => {
            el.value = profGen;
        });
        setProfs(newProfs);
        setTrigger(!trigger);
    }

    // btn exportar artículos guardados
    const handleExport = () => {
        let arts = [];
        let strArts = `Se cambiará el estado de los artículos: <ul class="mt-3 list-group" style="height: 40vh; overflow: auto;">`;
        cocheDespiece.forEach(art => {
            if (art.disponible.toString() === '1') {
                arts.push({
                    cod: art.id,
                    codb: art.ref,
                    articulo: art.articulo,
                    familia: art.familia,
                });
                strArts += `<li class="list-group-item" style="text-align: left; font-size: 15px;">` + art.articulo + '</li>';
            }
        });
        strArts += '</ul>';
        MySwal.fire({
            title: '¿Quieres exportar los artículos?',
            html: strArts,
            showCancelButton: true,
            confirmButtonText: 'Exportar',
        }).then((result) => {
            if (result.isConfirmed) {

                const changeDespiece = cocheDespiece.slice();

                for (let i = 0; i < changeDespiece.length; i++) {
                    changeDespiece[i].disponible = '0';
                }
                setCocheDespiece(changeDespiece);

                changeDespiece.forEach(el => {
                    setEditImgsArts(editImgsArts => [...editImgsArts, { id: el.id, ref: el.ref, blob: '', img: '' }]);
                });
                let toggles = [];
                changeDespiece.forEach(el => {
                    let obj = { id: el.id, loc: el.loc, imgerror: false, toggle: false, disabled: false };
                    toggles.push(obj);
                });
                setArrTog(toggles);

                changeStatus(token, JSON.stringify(arts));
                conectorPiezas(token, JSON.stringify(arts));
            }
        });
    }

    // eliminar coche
    const handleDelete = () => {
        MySwal.fire({
            title: '¿Quieres eliminar el vehículo?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCoche(token, modalcb.id).then((coche) => {
                    saveToParent(true);
                    setShow(false);
                }).finally(() => {
                });
            }
        });
    }

    // btn guardar cambios
    const handleSubmit = () => {
        MySwal.fire({
            title: '¿Quieres guardar los cambios?',
            showCancelButton: true,
            confirmButtonText: 'Guardar',
        }).then((result) => {

            if (result.isConfirmed) {
                let arrLocs = [];

                for (let i = 0; i < naves.length; i++) {
                    let loc = '';
                    if (naves[i] !== null) {
                        if (naves[i].value !== '') {
                            loc += naves[i].value + ".";
                        } else {
                            loc += "0.";
                        }
                    }

                    if (filas[i] !== null) {
                        if (filas[i].value !== '') {
                            loc += filas[i].value + ".";
                        } else {
                            loc += "0.";
                        }
                    }
                    if (estantes[i] !== null) {
                        if (estantes[i].value !== '') {
                            loc += estantes[i].value + ".";
                        } else {
                            loc += "0.";
                        }
                    }
                    if (profs[i] !== null) {
                        if (profs[i].value !== '') {
                            loc += profs[i].value;
                        } else {
                            loc += "0";
                        }
                    }

                    if (loc !== '') {
                        arrLocs.push(loc);
                    }
                }

                for (let i = 0; i < estadoArticulos.length; i++) {
                    const imgFile = imgsArts[i].img;
                    const refCar = estadoArticulos[i].id;
                    try {
                        let jsonStr = JSON.stringify({
                            idcoche: selCar.id,
                            ref: refCar,
                            disponible: 1,
                            ts: null,
                            idmarca: selCar.idmarca,
                            modelid: selCar.modelid,
                            loc: arrLocs[i]
                        });
                        altaDespiece(token, jsonStr);

                        const formData = new FormData();
                        formData.append('file', imgFile);
                        formData.set('idcoche', selCar.id);
                        formData.set('ref', refCar);

                        if (typeof imgFile === 'string') {
                            formData.set('isBase64', 1);
                        } else {
                            formData.set('isBase64', 0);
                        }

                        const urlApi = getApiUrl();
                        fetch(urlApi + 'uploadArtImg', {
                            method: 'post',
                            headers: {
                                'X-Api-Key': token.api_key,
                            },
                            body: formData,
                        }).then(
                            success => console.log(success)
                        ).catch(
                            error => console.log(error)
                        );
                    } catch (error) {
                        console.log(error);
                    }
                }
                setImgsArts([]);
                setEditImgsArts([]);
                saveToParent(true);
                setShow(false);
            }
        });
    }

    /**
     * Toggle/edit de artículos guardados
     */
    const handleEditLoc = (art) => {
        const objs = arrTog;
        let found = objs.find(el => el.id === art.id);
        if (found) {
            objs.forEach(el => {
                el.toggle = false;
                el.disabled = true;
            });
            const idx = objs.indexOf(found);
            objs[idx].toggle = true;
            objs[idx].disabled = false;
            setArrTog(objs);
        }
        setTrigger(!trigger);
    }

    /**
     * Cancelar toggle
     */
    const handleToggleCancel = (id) => {

        setEditImgError(false);

        let newEditImgsArts = editImgsArts.slice();
        let found = newEditImgsArts.find(el => el.id === id);
        if (found) {
            const idx = newEditImgsArts.indexOf(found);
            newEditImgsArts[idx].blob = '';
            newEditImgsArts[idx].img = '';
            setEditImgsArts(newEditImgsArts);
        }

        let prevNave = cocheDespiece.find(el => el.id === id);
        let objs = arrTog;
        let naveExiste = objs.find(el => el.id === id);

        objs.forEach(el => {
            el.disabled = false;
        });

        if (naveExiste) {
            const idx = objs.indexOf(naveExiste);
            objs[idx].loc = prevNave.loc;
            objs[idx].toggle = false;
            setArrTog(objs);
            setTrigger(!trigger);
        }
    }

    /**
     * Guardar cambios del artículo
     */
    const handleToggleSave = (id) => {
        let objs = arrTog;
        let naveExiste = objs.find(el => el.id === id);
        objs.forEach(el => {
            el.disabled = false;
        });
        if (naveExiste) {
            const idx = objs.indexOf(naveExiste);
            objs[idx].toggle = false;
            setArrTog(objs);
        }
        let objsDespiece = cocheDespiece;
        let found = objsDespiece.find(el => el.id === id);
        if (found) {
            const idx = objsDespiece.indexOf(found);
            objsDespiece[idx].loc = naveExiste.loc;
            setCocheDespiece(objsDespiece);
            let bodyData = JSON.stringify({
                idcochedet: objsDespiece[idx].id,
                loc: objsDespiece[idx].loc
            });
            updateLoc(token, bodyData);
        }
        let objsImgs = editImgsArts.slice();
        let foundImg = objsImgs.find(el => el.id === id);
        if (foundImg && foundImg.img !== '') {
            const formData = new FormData();
            formData.append('file', foundImg.img);
            formData.set('idcoche', selCar.id);
            formData.set('ref', foundImg.ref);

            if (typeof foundImg.img === 'string') {
                formData.set('isBase64', 1);
            } else {
                formData.set('isBase64', 0);
            }
            const urlApi = getApiUrl();
            fetch(urlApi + 'uploadArtImg', {
                method: 'post',
                headers: {
                    'X-Api-Key': token.api_key,
                },
                body: formData,
            }).then(
                success => console.log(success)
            ).catch(
                error => console.log(error)
            ).finally(() => {
                let arr = editImgsArts.slice();
                let idx = arr.indexOf(foundImg);
                arr[idx].img = '';
                setEditImgsArts(arr);
            });
        }
        setTrigger(!trigger);
    }

    // edit nave
    const handleEditNave = (e, art) => {
        let objs = arrTog;
        let found = objs.find(el => el.id === art.id);
        if (found) {
            let splitLoc = found.loc.split('.');
            let newLoc = e.target.value + "." + splitLoc[1] + "." + splitLoc[2] + "." + splitLoc[3];
            const idx = objs.indexOf(found);
            objs[idx].loc = newLoc;
            setArrTog(objs);
        }
        setTrigger(!trigger);
    }

    // edit nave
    const handleEditFila = (e, art) => {
        let objs = arrTog;
        let found = objs.find(el => el.id === art.id);
        if (found) {
            let splitLoc = found.loc.split('.');
            let newLoc = splitLoc[0] + "." + e.target.value + "." + splitLoc[2] + "." + splitLoc[3];
            const idx = objs.indexOf(found);
            objs[idx].loc = newLoc;
            setArrTog(objs);
        }
        setTrigger(!trigger);
    }

    // edit nave
    const handleEditEstante = (e, art) => {
        let objs = arrTog;
        let found = objs.find(el => el.id === art.id);
        if (found) {
            let splitLoc = found.loc.split('.');
            let newLoc = splitLoc[0] + "." + splitLoc[1] + "." + e.target.value + "." + splitLoc[3];
            const idx = objs.indexOf(found);
            objs[idx].loc = newLoc;
            setArrTog(objs);
        }
        setTrigger(!trigger);
    }

    // edit nave
    const handleEditProf = (e, art) => {
        let objs = arrTog;
        let found = objs.find(el => el.id === art.id);
        if (found) {
            let splitLoc = found.loc.split('.');
            let newLoc = splitLoc[0] + "." + splitLoc[1] + "." + splitLoc[2] + "." + e.target.value;
            const idx = objs.indexOf(found);
            objs[idx].loc = newLoc;
            setArrTog(objs);
        }
        setTrigger(!trigger);
    }

    // toggle edit
    const valueToggle = (id) => {
        let found = arrTog.find(el => el.id === id);
        return found.toggle;
    }

    // toggle edit disabled
    const valueEditDisable = (art) => {
        if (art.disponible.toString() === '1') {
            let found = arrTog.find(el => el.id === art.id);
            return found.disabled;
        }
        return true;
    }

    // value nave
    const valueEditNave = (id) => {
        let found = arrTog.find(el => el.id === id);
        let splitLoc = found.loc.split('.');
        return splitLoc[0];
    }

    // value fila
    const valueEditFila = (id) => {
        let found = arrTog.find(el => el.id === id);
        let splitLoc = found.loc.split('.');
        return splitLoc[1];
    }

    // value estante
    const valueEditEstante = (id) => {
        let found = arrTog.find(el => el.id === id);
        let splitLoc = found.loc.split('.');
        return splitLoc[2];
    }

    // value prof
    const valueEditProf = (id) => {
        let found = arrTog.find(el => el.id === id);
        let splitLoc = found.loc.split('.');
        return splitLoc[3];
    }

    // desactiva el botón de guardar cambios si no hay artículos añadidos
    // o si hay errores de imagen
    const hayCambios = () => {
        if (estadoArticulos.length <= 0) {
            return true;
        }

        if (imgFormatError) {
            return true;
        }

        if (imgSizeError) {
            return true;
        }
        return false;
    }

    /**
     * Functions
     */
    // cuando se elimina un artículo modificado, se elimina de su correspondiente array
    function setFormLoc(array, id, tipo) {
        let objetos = array;
        let itemExiste = objetos.find(el => el.id === id);
        if (itemExiste) {
            const idx = objetos.indexOf(itemExiste);
            objetos.splice(idx, 1);

            switch (tipo) {
                case 'n':
                    setNaves(objetos);
                    break;
                case 'f':
                    setFilas(objetos);
                    break;
                case 'e':
                    setEstantes(objetos);
                    break;
                case 'p':
                    setProfs(objetos);
                    break;
                default:
                    break;
            }
        }
    }

    // comprobar si el checkbox debería estar marcado
    function checkboxIsChecked(id) {
        let isInDespiece = cocheDespiece.find(el => el.ref === id);
        if (isInDespiece) {
            return true;
        } else {
            let isInArray = estadoArticulos.find(el => el.id === id);
            return (isInArray ? true : false);
        }
    }

    // si ya hay artículos previos, los desactivamos
    function checkboxDisabled(id) {
        let isInArray = cocheDespiece.find(el => el.ref === id);
        return (isInArray ? true : false);
    }

    // para obtener la localización de los artículos guardados
    // si pulsamos en el botón de editar, mostraremos los inputs donde especificar nave, fila, estante, profundidad
    function parseLocalizacion(art) {
        let splitLoc = art.loc.split('.');

        return (
            <div className="text-muted">
                {!valueToggle(art.id) ? (
                    <div className="row">
                        <small>Nave {splitLoc[0]} Fila {splitLoc[1]} Estante {splitLoc[2]} Prof {splitLoc[3]}</small>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-3">
                            <input type="text" className='form-control' value={valueEditNave(art.id)} onChange={(e) => handleEditNave(e, art)} style={{ width: '45px' }} />
                        </div>
                        <div className="col-3">
                            <input type="text" className='form-control' value={valueEditFila(art.id)} onChange={(e) => handleEditFila(e, art)} style={{ width: '45px' }} />
                        </div>
                        <div className="col-3">
                            <input type="text" className='form-control' value={valueEditEstante(art.id)} onChange={(e) => handleEditEstante(e, art)} style={{ width: '45px' }} />
                        </div>
                        <div className="col-3">
                            <input type="text" className='form-control' value={valueEditProf(art.id)} onChange={(e) => handleEditProf(e, art)} style={{ width: '45px' }} />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    function WebCamComp(comp) {
        const videoConstraints = {
            facingMode: { exact: "environment" }
        };
        return (
            <div>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    onClick={() => capture(comp)}
                    screenshotFormat="image/jpeg"
                    style={{ width: '99%', height: '300px', objectFit: 'cover' }}
                    videoConstraints={videoConstraints}
                    onUserMediaError={(error) => handleError(error)}
                />
            </div>
        );
    };


    const onCocheImgChange = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        let imgData = e.target.files[0];
        let imgType = e.target.files[0].type;
        let typeSplit = imgType.split('/');

        if (typeSplit[1] !== 'jpg' && typeSplit[1] !== 'jpeg' && typeSplit[1] !== 'png' && typeSplit[1] !== 'gif' && typeSplit[1] !== 'jfif') {
            setCocheImgFormatError(true);
        } else {
            new Compressor(imgData, {
                strict: true,
                checkOrientation: true,
                minWidth: 0,
                minHeight: 0,
                height: 720,
                resize: 'none',
                quality: 0.4,
                mimeType: 'auto',
                convertTypes: 'image/jpg,image/jpeg,image/png,image/gif,image/jfif',
                convertSize: 7000,
                success(result) {
                    let resultType = result.type;
                    let resultTypeSplit = resultType.split('/');
                    let imgSize = result.size;

                    let imgerror = false;

                    if (resultTypeSplit[0] !== 'image') {
                        imgerror = true;
                        setCocheImgFormatError(true);
                    }

                    if (resultTypeSplit[1] !== 'jpg' && resultTypeSplit[1] !== 'jpeg' && resultTypeSplit[1] !== 'png' && resultTypeSplit[1] !== 'gif' && resultTypeSplit[1] !== 'jfif') {
                        imgerror = true;
                        setCocheImgFormatError(true);
                    }

                    if (imgSize > 700000) {
                        imgerror = true;
                        setCocheImgSizeError(true);
                    }

                    if (!imgerror) {
                        setCocheImgFormatError(false);
                        setCocheImgSizeError(false);
                        var file = new File([result], result.name);
                        setCocheImage(file);
                    }
                },
                error(err) {
                    console.log(err.message);
                },
            });
        }
    }

    useEffect(() => {
        if (cocheImage !== null) {
            if (typeof cocheImage === 'string') {
                setCocheImageBlob(cocheImage);
                setShowCam(!showCam);
            } else {
                const objectUrl = URL.createObjectURL(cocheImage);
                setCocheImageBlob(objectUrl);
            }
        }
    }, [cocheImage]);

    // se selecciona el input file del coche abierto en el modal
    const handleCocheImg = () => {
        cocheImgRef.current.click();
    }

    const handleCameraShow = () => {
        setShowCam(!showCam);
    }

    const handleCancelImgCoche = () => {
        setCocheImageBlob(null);
        setCocheImage(null);
        cocheImgRef.current.value = "";
    }

    const handleSaveImgCoche = () => {
        MySwal.fire({
            title: '¿Quieres guardar los cambios?',
            showCancelButton: true,
            confirmButtonText: 'Guardar',
        }).then((result) => {
            if (result.isConfirmed) {
                setSelCarImg(cocheImageBlob);

                if (cocheImage) {
                    const formData = new FormData();
                    formData.append('file', cocheImage);
                    formData.set('fileid', selCar.id);

                    if (typeof cocheImage === 'string') {
                        formData.set('isBase64', 1);
                    } else {
                        formData.set('isBase64', 0);
                    }
                    const urlApi = getApiUrl();
                    fetch(urlApi + 'uploadimg', {
                        method: 'post',
                        headers: {
                            'X-Api-Key': token.api_key,
                        },
                        body: formData,
                    }).then(
                        success => console.log(success)
                    ).catch(
                        error => console.log(error)
                    ).finally(() => {
                        cocheImgRef.current.value = "";
                        setCocheImageBlob(null);
                        setCocheImage(null);
                        saveToParent(true);
                    });
                }
            }
        });
    }

    function MostrarCam() {
        if (showCam) {
            if (errorCam) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        No se puede utilizar la cámara
                    </div>
                );
            } else {
                const videoConstraints = {
                    facingMode: { exact: "environment" }
                };
                return (
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        onClick={capture}
                        screenshotFormat="image/jpeg"
                        style={{ width: '300px', height: '200px', objectFit: 'cover' }}
                        videoConstraints={videoConstraints}
                        onUserMediaError={(error) => handleError(error)}
                    />
                );
            }
        } else {

            if (cocheImgFormatError) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        Por favor utiliza un formato de imagen válido (.jpg, .jpeg, .png, .gif, .jfif).
                    </div>
                );
            }

            if (cocheImgSizeError) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        El peso de la imagen es demasiado grande.
                    </div>
                );
            }

            if (cocheImageBlob) {
                return (
                    <div>
                        <label>Preview:</label>
                        <div className="d-flex align-items-center justify-content-center">
                            <img className="rounded" style={{ width: '300px', height: '200px', objectFit: 'cover' }} src={cocheImageBlob} alt='captured' />
                        </div>
                    </div>
                );
            } else {
                return (
                    null
                );
            }
        }
    }

    // render modaldespiece
    return (
        <Modal show={show} onHide={handleClose}
            dialogClassName="modal-xl"
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-center"
            style={{ paddingLeft: 0, margin: 0 }}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Despiece de {selCar.matricula}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="d-flex justify-content-between">
                    <div className="mb-2">
                        { /* Botón seleccionar imagen */}
                        <input key={selCar.id} ref={cocheImgRef} onChange={(e) => onCocheImgChange(e)} type="file" id="formFile" accept="image/jpg, image/jpeg, image/png, image/gif, image/jfif" style={{ display: 'none' }} />
                        <button value={selCar.id} className="btn btn-warning btn-sm me-1 mt-1" type="button" onClick={(e) => handleCocheImg(e)} ><Paperclip color="white" size={15} /></button>

                        { /* Botón cámara */}
                        <button value={selCar.id} className="btn btn-primary btn-sm me-1 mt-1" type="button" onClick={() => handleCameraShow()} >
                            {showCam ? (
                                <CameraOff size={'15px'} />
                            ) : (
                                <Camera size={'15px'} />
                            )}
                        </button>
                    </div>

                    {cocheImageBlob ?
                        (
                            <div>
                                <button className="btn btn-sm btn-danger me-2" onClick={() => handleCancelImgCoche()}>Cancelar cambio de imagen</button>
                                <button className="btn btn-sm btn-info" onClick={() => handleSaveImgCoche()}>Guardar imagen</button>
                            </div>

                        ) : (
                            null
                        )
                    }
                </div>

                {cocheImageBlob ? (
                    <div>
                        <MostrarCam />
                    </div>
                ) : (
                    <div>
                        {selCarImg !== '' ? (
                            <div className="position-relative">
                                <img className="card-img" src={selCarImg} alt="Card cap" style={{ height: '100px', objectFit: 'cover' }} />
                            </div>
                        ) : (
                            <div className="bg-light-danger text-danger d-inline-block py-3 rounded d-flex align-items-center justify-content-center mb-1" style={{ height: '100px' }}>
                                <AlertCircle size={'30%'} />
                            </div>
                        )}
                    </div>
                )}



                <ul className="nav nav-pills nav-fill mt-4 flex-nowrap" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-bs-toggle="tab" href="#guardados" role="tab">
                            <span>Guardados</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#seleccion" role="tab">
                            <span>Selección</span>
                        </a>
                    </li>
                    {estadoArticulos.length > 0 ? (
                        <li className="nav-item">
                            <a className="nav-link position-relative" data-bs-toggle="tab" href="#modificados" role="tab">
                                <span>Modificados</span>
                                <span className="position-absolute top-0 start-90 translate-middle badge rounded-pill bg-danger">
                                    {estadoArticulos.length}
                                    <span className="visually-hidden">artículos seleccionados</span>
                                </span>
                            </a>
                        </li>
                    ) : (
                        null
                    )}

                </ul>
                <div className="tab-content mt-2">
                    <div className="tab-pane active" id="guardados" role="tabpanel">
                        <div className="row">
                            <ArticulosGuardados WebCamComp={WebCamComp} showCam={showCam} errorCam={errorCam} capture={capture} videoConstraints={videoConstraints} handleError={handleError} handleShowCam={handleShowCam} cocheDespiece={cocheDespiece} valueToggle={valueToggle} valueEditDisable={valueEditDisable} handleEditLoc={handleEditLoc} handleSelecEditImg={handleSelecEditImg} onFileEditChange={onFileEditChange} editImage={editImage} editImgsArts={editImgsArts} imgEditPreviewArts={imgEditPreviewArts} handleToggleSave={handleToggleSave} handleToggleCancel={handleToggleCancel} parseLocalizacion={parseLocalizacion} loading={loading} editImgError={editImgError} imgEditSizeError={imgEditSizeError} imgEditFormatError={imgEditFormatError} selectedEditArtImg={selectedEditArtImg} ref={editFileRef} />
                        </div>
                    </div>
                    <div className="tab-pane" id="seleccion" role="tabpanel">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3 sticky-top">
                                    <form onSubmit={onFormSearchSubmit}>
                                        <div className="input-group">
                                            <input type="search" className="form-control" placeholder="Buscar por artículos..." aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => setSearchInput(e.target.value)} />
                                            <button className="btn btn-info font-weight-medium" type="submit" onClick={handleClickBuscar}>Buscar</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="mb-3">
                                    <Select options={optFamilia} onChange={handleFamilia} value={selFamilia} placeholder="Buscar por familia" isClearable={true} />
                                </div>
                                {optArticulos.length > 0 ? <TableArticulos cocheDespiece={cocheDespiece} optArticulos={optArticulos} handleEstado={handleEstado} checkboxIsChecked={checkboxIsChecked} checkboxDisabled={checkboxDisabled} ref={tableRef} /> : null}
                            </div>
                        </div>
                    </div>
                    {estadoArticulos.length > 0 ? (
                        <div className="tab-pane" id="modificados" role="tabpanel">
                            <ArticulosModificados WebCamComp={WebCamComp} imgError={imgError} imgSizeError={imgSizeError} imgFormatError={imgFormatError} imgPreviewArts={imgPreviewArts} imgEditPreviewArts={imgEditPreviewArts} estadoArticulos={estadoArticulos} camSel={camSel} selectedArtImg={selectedArtImg} valueNave={valueNave} valueFila={valueFila} valueEstante={valueEstante} valueProf={valueProf} naveGen={naveGen} filaGen={filaGen} estanteGen={estanteGen} profGen={profGen} handleGeneral={handleGeneral} handleEstado={handleEstado} onFileChange={onFileChange} handleSelecImg={handleSelecImg} handleChangeNave={handleChangeNave} handleChangeFilas={handleChangeFilas} handleChangeEstantes={handleChangeEstantes} handleChangeProfs={handleChangeProfs} setNaveGen={setNaveGen} setFilaGen={setFilaGen} setEstanteGen={setEstanteGen} setProfGen={setProfGen} showCam={showCam} errorCam={errorCam} capture={capture} videoConstraints={videoConstraints} handleError={handleError} handleShowCam={handleShowCam} ref={fileRef} />
                        </div>
                    ) : (
                        null
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="me-auto" variant="danger" onClick={handleDelete}>
                    Eliminar
                </Button>
                <Button variant="primary" onClick={handleExport}>
                    Exportar a Contafact
                </Button>
                <Button variant="info" disabled={hayCambios()} onClick={handleSubmit}>
                    Guardar cambios
                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const ImgSizeErrorLabel = () => {
    return (
        <div className="invalid-feedback" style={{ display: 'block' }}>
            El peso de la imagen es demasiado grande.
        </div>
    );
}

const ImgFormatErrorLabel = () => {
    return (
        <div className="invalid-feedback" style={{ display: 'block' }}>
            Por favor utiliza un formato de imagen válido (.jpg, .jpeg, .png, .gif, .jfif).
        </div>
    );
}


const ArticulosGuardados = forwardRef((props, editFileRef) => {
    function Loader() {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    function NoElements() {
        return (
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex flex-row">
                            <div className="ms-2 align-self-center">
                                <h3 className="mb-0">Coche sin artículos guardados</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function MostrarCam() {
        if (props.showCam) {
            if (props.errorCam) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        No se puede utilizar la cámara
                    </div>
                );
            } else {
                return (
                    props.WebCamComp(0)
                );
            }
        }
    }

    const listArticulos = props.cocheDespiece.map((articulo, i) => {

        function getImgArticulo(articulo) {
            if (props.imgEditPreviewArts(articulo.id)) {
                return (
                    <img className="rounded img-fluid" alt='preview de imagen de artículo' src={props.imgEditPreviewArts(articulo.id)} style={{ height: '60px', width: '70px', objectFit: 'cover' }} />
                );
            } else {
                if (articulo.imagePath !== '') {
                    return (
                        <div style={{ height: '60px', width: '70px', objectFit: 'cover' }}>
                            <img className="rounded img-fluid" alt='imagen de artículo' src={articulo.imagePath} style={{ height: '60px', width: '70px', objectFit: 'cover' }} />
                        </div>
                    );
                } else {
                    return (
                        <div className="bg-light-danger text-danger d-inline-block py-3 rounded d-flex align-items-center justify-content-center mb-1" style={{ height: '60px', width: '70px', objectFit: 'cover' }}>
                            <AlertCircle size={'20px'} />
                        </div>
                    );
                }
            }
        }

        return (
            <li key={articulo.id} id={articulo.id} className="list-group-item d-flex flex-column" >
                <div className="d-flex flex-row">
                    <div className="me-3" style={{ height: '', width: '80px', minWidth: '80px', objectFit: 'cover' }}>
                        {getImgArticulo(articulo)}

                        {
                            !props.valueToggle(articulo.id) ?
                                (
                                    null
                                ) : (
                                    <div>
                                        { /* Botón seleccionar imagen */}
                                        <input key={articulo.id} ref={el => editFileRef.current[i] = el} onChange={props.onFileEditChange} type="file" id="formFile" accept="image/jpg, image/jpeg, image/png, image/gif, image/jfif" style={{ display: 'none' }} />
                                        <button value={articulo.id} className="btn btn-warning btn-sm me-1 mt-1" type="button" onClick={(e) => props.handleSelecEditImg(e, i)} ><Paperclip color="white" size={15} /></button>

                                        { /* Botón cámara */}
                                        <button value={articulo.id} className="btn btn-primary btn-sm me-1 mt-1" type="button" onClick={() => props.handleShowCam(articulo.id)} >
                                            {props.showCam ? (
                                                <CameraOff color="white" size={15} />
                                            ) : (
                                                <Camera color="white" size={15} />
                                            )}
                                        </button>
                                    </div>
                                )
                        }
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-row align-items-center'>
                            {!props.valueToggle(articulo.id) ?
                                (
                                    <div>
                                        {articulo.disponible.toString() === '1' ? (
                                            <div>
                                                <button onClick={() => props.handleEditLoc(articulo)} type="button" className="btn btn-sm btn-warning align-items-center me-3" disabled={props.valueEditDisable(articulo)}>
                                                    <Edit2 size={'12px'} />
                                                </button>
                                            </div>
                                        ) : (
                                            <span>
                                            </span>
                                        )}
                                        {/*articulo.disponible.toString() === '1' ? (
                                            <div>
                                                <button onClick={() => props.handleEditLoc(articulo)} type="button" className="btn btn-sm btn-warning align-items-center me-3" disabled={props.valueEditDisable(articulo)}>
                                                    <Edit2 size={'12px'} />
                                                </button>
                                            </div>
                                        ) : (
                                            <span className="bg-light-danger align-items-center me-3" disabled style={{ padding: '0.25rem 0.5rem', borderRadius: '4px' }}>
                                                <i className="mdi mdi-block-helper text-danger"></i>
                                            </span>
                                        )*/}
                                    </div>

                                ) : (
                                    null
                                )
                            }

                            <div className='d-flex flex-column me-1'>
                                <small>{articulo.articulo}</small>
                                <span className="small text-info">{articulo.familia}</span>
                            </div>
                            {!props.valueToggle(articulo.id) ? (
                                null
                            ) : (
                                <div className='d-flex ms-auto ms-1'>
                                    { /* Botón aceptar cambios */}

                                    {props.editImgError ? (
                                        <button disabled className="btn btn-sm btn-success"><i className="mdi mdi-check"></i></button>
                                    ) : (
                                        <button onClick={() => props.handleToggleSave(articulo.id)} className="btn btn-sm btn-success"><i className="mdi mdi-check"></i></button>
                                    )}

                                    { /* Botón cancelar cambios */}
                                    <button onClick={() => props.handleToggleCancel(articulo.id)} className="btn btn-sm btn-danger ms-3"><i className="mdi mdi-close"></i></button>
                                </div>
                            )}
                        </div>
                        {props.parseLocalizacion(articulo)}
                    </div>
                </div>

                {props.showCam && props.valueToggle(articulo.id) ? (
                    <MostrarCam />
                ) : (
                    <>
                        {props.imgEditFormatError && props.selectedEditArtImg === articulo.id ? (
                            <ImgFormatErrorLabel />
                        ) : (
                            null
                        )}

                        {props.imgEditSizeError && props.selectedEditArtImg === articulo.id ? (
                            <ImgSizeErrorLabel />
                        ) : (
                            null
                        )}
                    </>
                )}
            </li>
        );
    });

    if (!props.loading) {

        if (props.cocheDespiece.length > 0) {
            return (
                <ul className="list-group ps-3" >
                    {listArticulos}
                </ul>
            );
        } else {
            return (
                <NoElements />
            );
        }
    } else {
        return (
            <Loader />
        );
    }
});

const ArticulosModificados = forwardRef((props, fileRef) => {
    function MostrarCam() {
        if (props.showCam) {
            if (props.errorCam) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        No se puede utilizar la cámara
                    </div>
                );
            } else {
                return (
                    props.WebCamComp(1)
                );
            }
        }
    }

    const listArticulos = props.estadoArticulos.map((articulo, i) => {
        return (
            <li key={articulo.id} id={articulo.id} className="list-group-item d-flex flex-column" >
                <div className='row'>
                    { /* Article img */}
                    <div className="col-auto">
                        {props.imgPreviewArts(articulo.id) ?
                            (
                                <img alt='preview de la imagen de artículo' className="rounded" src={props.imgPreviewArts(articulo.id)} style={{ height: '60px', width: '70px', objectFit: 'cover' }} />
                            ) : (
                                <div className="bg-light-danger text-danger d-inline-block py-3 rounded d-flex align-items-center justify-content-center" style={{ height: '60px', width: '70px', objectFit: 'cover' }}>
                                    <AlertCircle size={'20px'} />
                                </div>
                            )}
                    </div>

                    <div className="col">
                        { /* Article name */}
                        <div className='d-flex flex-column'>
                            <small>{articulo.articulo}</small>
                            <span className="small text-info">{articulo.familia}</span>
                        </div>
                    </div>

                    <div className="col-2">
                        { /* Delete button */}
                        <button className="btn btn-danger btn-sm me-1" type="button" onClick={() => props.handleEstado(articulo.id)}><Trash color="white" size={15} style={{ maxWidth: '15px' }} /></button>
                    </div>
                </div>
                <div className='col d-flex align-items-center'>
                    { /* Select file button */}
                    <input key={articulo.id} ref={el => fileRef.current[i] = el} onChange={props.onFileChange} type="file" id="formFile" accept="image/png, image/jpeg" style={{ display: 'none' }} />
                    <button value={articulo.id} className="btn btn-warning btn-sm me-1" type="button" onClick={(e) => props.handleSelecImg(e, i)} ><Paperclip color="white" size={15} style={{ maxWidth: '15px' }} /></button>

                    { /* Camera button */}
                    <button className="btn btn-primary btn-sm me-1" type="button" onClick={() => props.handleShowCam(articulo.id)}>

                        {props.showCam ? (
                            <CameraOff color="white" size={15} style={{ maxWidth: '15px' }} />
                        ) : (
                            <Camera color="white" size={15} style={{ maxWidth: '15px' }} />
                        )}
                    </button>

                    <form className="d-flex">
                        <div className='col-3'>
                            <input type="text" className="form-control" placeholder="nave" value={props.valueNave(articulo.id)} onChange={(e) => props.handleChangeNave(e, articulo.id)} />
                        </div>
                        <div className='col-3'>
                            <input type="text" className="form-control" placeholder="fila" value={props.valueFila(articulo.id)} onChange={(e) => props.handleChangeFilas(e, articulo.id)} />
                        </div>
                        <div className='col-3'>
                            <input type="text" className="form-control" placeholder="estante" value={props.valueEstante(articulo.id)} onChange={(e) => props.handleChangeEstantes(e, articulo.id)} />
                        </div>
                        <div className='col-3'>
                            <input type="text" className="form-control" placeholder="profundidad" value={props.valueProf(articulo.id)} onChange={(e) => props.handleChangeProfs(e, articulo.id)} />
                        </div>
                    </form>
                </div>
                {props.imgFormatError && props.selectedArtImg === articulo.id ? (
                    <ImgFormatErrorLabel />
                ) : (
                    null
                )}

                {props.imgSizeError && props.selectedArtImg === articulo.id ? (
                    <ImgSizeErrorLabel />
                ) : (
                    null
                )}

                {props.showCam && props.camSel === articulo.id ? (
                    <MostrarCam />
                ) : (
                    null
                )}
            </li>
        );
    });
    return (
        <div className='col-xs-5'>
            <label>Parámetros generales</label>
            <br />
            <form onSubmit={props.handleGeneral}>
                <div className="row">
                    <div className='col-2'>
                        <label className='text-muted'><small>Nave</small></label>
                        <input style={{ width: '50px' }} type="text" name="nave" className="form-control" placeholder="0" value={props.naveGen} onChange={(e) => props.setNaveGen(e.target.value)} />
                    </div>
                    <div className='col-2'>
                        <label className='text-muted'><small>Fila</small></label>
                        <input style={{ width: '50px' }} type="text" name="fila" className="form-control" placeholder="0" value={props.filaGen} onChange={(e) => props.setFilaGen(e.target.value)} />
                    </div>
                    <div className='col-2'>
                        <label className='text-muted'><small>Estante</small></label>
                        <input style={{ width: '50px' }} type="text" name="estante" className="form-control" placeholder="0" value={props.estanteGen} onChange={(e) => props.setEstanteGen(e.target.value)} />
                    </div>
                    <div className='col-2'>
                        <label className='text-muted'><small>Profundidad</small></label>
                        <input style={{ width: '50px' }} type="text" name="profundidad" className="form-control" placeholder="0" value={props.profGen} onChange={(e) => props.setProfGen(e.target.value)} />
                    </div>
                    <div className='col-4 d-flex align-items-end justify-content-end'>
                        <button type="submit" className='btn btn-info btn-sm'><Check /></button>
                    </div>
                </div>
            </form>
            <br />
            <ul className="list-group">
                {listArticulos}
            </ul>
        </div>
    );
});

const TableArticulos = forwardRef((props, tableRef) => {
    const checkMuted = (art) => {
        let isInArray = props.cocheDespiece.find(el => el.ref === art.id);
        if (isInArray) {
            return (
                <div>
                    <h6 className="user-name mb-0 font-weight-medium text-muted">{art.articulo}</h6>
                    <span className="small text-info text-muted">{art.familia}</span>
                </div>
            );
        } else {
            return (
                <div>
                    <h6 className="user-name mb-0 font-weight-medium">{art.articulo}</h6>
                    <span className="small text-info">{art.familia}</span>
                </div>
            );
        }
    }

    const listArticulos = props.optArticulos.map((articulo) => {
        return (
            <tr key={articulo.id} className="search-items">
                <td>
                    <div className="n-chk align-self-center text-center">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input contact-chkbox info" id={articulo.id} onChange={() => props.handleEstado(articulo.id)} checked={props.checkboxIsChecked(articulo.id)} disabled={props.checkboxDisabled(articulo.id)} />
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="user-meta-info">
                            {checkMuted(articulo)}
                        </div>
                    </div>
                </td>
            </tr>
        );
    });

    return (
        <div>
            <div className="table-responsive" ref={tableRef}>
                <table className="table search-table v-middle">
                    <thead className="header-item">
                        <tr>
                            <th>Marcar</th>
                            <th>Artículo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listArticulos}
                    </tbody>
                </table>
            </div>
        </div>
    );
});


export default ModalDespiece;