import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { getCoches, getFamilias, getPiezas,getDespieces } from '../Data/data';

const OffCanvasFiltersArt = ({ tokenLayout, artData }) => {
    // form options
    const [optCoches, setOptCoches] = useState([]);
    const [optFamilias, setOptFamilias] = useState([]);
    const [optArticulos, setOptArticulos] = useState([]);

    // form values
    const [coche, setCoche] = useState('');
    const [familia, setFamilia] = useState('');
    const [articulo, setArticulo] = useState('');
    const [nave, setNave] = useState('');
    const [fila, setFila] = useState('');
    const [estante, setEstante] = useState('');
    const [prof, setProf] = useState('');

    // clear filter button
    const [showClear, setShowClear] = useState(false);

    // data list
    const [jsonArticulos, setJsonArticulos] = useState([]);

    // fetch data
    useEffect(() => {
        getDespieces(tokenLayout).then((piezas) => {
            if (piezas !== undefined) {
                setJsonArticulos(piezas);
            }
        });

        getCoches(tokenLayout).then((coches) => {
            if (coches !== undefined) {
                let newArr = [];
                coches.forEach(coche => {
                    let strMat = '';
                    if (coche.matricula !== '') {
                        strMat = coche.matricula;
                    } else {
                        strMat = 'Sin matrícula';
                    }
                    newArr.push({ value: coche.id, label: strMat });
                });
                setOptCoches(newArr);
            }
        });

        getFamilias(tokenLayout).then((familias) => {
            if (familias !== undefined) {
                let arrFamilias = [];
                familias.forEach(familia => {
                    arrFamilias.push({ value: familia.id, label: familia.familia });
                });
                setOptFamilias(arrFamilias);
            }
        });

        getPiezas(tokenLayout).then((piezas) => {
            if (piezas !== undefined) {
                let arrArticulos = [];
                piezas.forEach(pieza => {
                    arrArticulos.push({ value: pieza.id, label: pieza.articulo });
                });
                setOptArticulos(arrArticulos);
            }
        });
    }, [tokenLayout]);


    // cambios en selectores
    useEffect(() => {
        let origData = jsonArticulos;
        
        if (coche === '' && familia === '' && articulo === '' && nave === '' && fila === '' && estante === '' && prof === '') {
            setShowClear(false);
            artData(jsonArticulos);
        } else {
            if (coche !== '') {
                origData = origData.filter(el => el.idcoche === coche.value);
            }

            if (familia !== '') {
                origData = origData.filter(el => el.familia === familia.label);
            }

            if (articulo !== '') {
                origData = origData.filter(el => el.ref === articulo.value);
            }

            if (nave !== '') {
                origData = origData.filter(el => el.loc[0] === nave);
            }

            if (fila !== '') {
                origData = origData.filter(el => el.loc[2] === fila);
            }

            if (estante !== '') {
                origData = origData.filter(el => el.loc[4] === estante);
            }

            if (prof !== '') {
                origData = origData.filter(el => el.loc[6] === prof);
            }
            artData(origData);
            setShowClear(true);
        }
    }, [coche, familia, articulo, nave, fila, estante, prof]);

    const clearFilters = () => {

        // original data
        artData(jsonArticulos);

        // default value of selects
        setCoche('');
        setFamilia('');
        setArticulo('');
        setNave('');
        setFila('');
        setEstante('');
        setProf('');

        // btn clear filters
        setShowClear(false);
    }

    const ClearFiltersBtn = () => {
        return (
            <Button variant="danger" className="btn btn-sm btn-primary text-white ms-2" onClick={clearFilters}>
                <i className="mdi mdi-delete"></i>
            </Button>
        );
    }

    const handleCoches = (e) => {
        if (e !== null) {
            try {
                setCoche(e);
            } catch (error) {
                setCoche('');
            }
        } else {
            setCoche('');
        }
    }

    const handleFamilias = (e) => {
        if (e !== null) {
            try {
                setFamilia(e);
            } catch (error) {
                setFamilia('');
            }
        } else {
            setFamilia('');
        }
    }

    const handleArticulos = (e) => {
        if (e !== null) {
            try {
                setArticulo(e);
            } catch (error) {
                setArticulo('');
            }
        } else {
            setArticulo('');
        }
    }


    return (
        <>
            <Button variant="primary" className="btn btn-sm btn-primary text-white" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilterArt">
                <i className="mdi mdi-filter"></i>
            </Button>
            {showClear ? <ClearFiltersBtn /> : null}

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasFilterArt" data-bs-scroll="true" aria-labelledby="offcanvasFilterArtLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasFilterArtLabel">Filtros Artículos</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" tabIndex="-1"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label>Coche</label>
                                    <Select options={optCoches} isClearable='true' onChange={handleCoches} value={coche} placeholder="Coches" />
                                </div>
                                <div className="mb-3">
                                    <label>Familia</label>
                                    <Select options={optFamilias} isClearable='true' onChange={handleFamilias} value={familia} placeholder="Familias" />
                                </div>
                                <div className="mb-3">
                                    <label>Artículo</label>
                                    <Select options={optArticulos} isClearable='true' onChange={handleArticulos} value={articulo} placeholder="Artículos" />
                                </div>
                                <div className="mb-3">
                                    <label>Nave</label>
                                    <input type="number" min="0" max="99" step="1" className="form-control" onChange={(e) => setNave(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label>Fila</label>
                                    <input type="number" min="0" max="99" step="1" className="form-control" onChange={(e) => setFila(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label>Estante</label>
                                    <input type="number" min="0" max="99" step="1" className="form-control" onChange={(e) => setEstante(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label>Profundidad</label>
                                    <input type="number" min="0" max="99" step="1" className="form-control" onChange={(e) => setProf(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OffCanvasFiltersArt;