import React, { useEffect, useState, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import './apidoc.css';
import DataTableBase from '../Datatable/Datatable';

import { changePass, genApiKey, getUserList, getSessionLogs, getVersionLogs, checkPass } from '../Data/data';

const optDefinitions = [
  { value: 0, label: 'Origen' },
  { value: 1, label: 'Destino' }
];

// columnas para el datatable de usuarios
const columnsUsers = [
  {
    name: 'Nombre de usuario',
    selector: row => row.xnom,
    sortable: true,
  },
  {
    name: 'Email',
    selector: row => row.xmail,
    sortable: true,
  },
];

// columnas para el datatable de sesiones
const columnsSessions = [
  {
    name: 'User',
    selector: row => row.username,
  },
  {
    name: 'Estado',
    selector: row => row.Ok,
    sortable: true,
  },
  {
    name: 'Inicio de sesión',
    selector: row => row.logintime,
    sortable: true,
  },
  {
    name: 'Ip',
    selector: row => row.remote_host,
    sortable: true,
  },
];

// columnas para el datatable de versiones
const columnsVersion = [
  {
    name: 'Fecha',
    selector: row => row.fecha,
    sortable: true,
  },
  {
    name: 'Version',
    selector: row => row.version,
    sortable: true,
  },
];

const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>CSV</Button>;

const ModalConfig = ({ token, modalClaveCb, modalClaveShow, guardarClave }) => {

  // user list
  const [users, setUsers] = useState([]);

  // session logs
  const [logs, setLogs] = useState([]);
  const [origLogs, setOrigLogs] = useState([]);

  // version logs
  const [version, setVersion] = useState([]);

  // definitions select
  const [definition, setDefinition] = useState({ value: 0, label: 'Origen' });

  // modal hooks
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    modalClaveShow(false);
  }

  // pass change hooks
  const [actualPass, setActualPass] = useState('');
  const [pass, setPass] = useState('');
  const [retypePass, setRetypePass] = useState('');
  const [actualPassError, setActualPassError] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [minCharsError, setMinCharsError] = useState(false);

  // generate api key
  const [newApiKey, setNewApiKey] = useState('');

  // session log filter
  let d = new Date();
  let desde = new Date(d.getFullYear(), d.getMonth(), 1);
  let dDesde = desde.getFullYear() + "-" + (desde.getMonth() + 1) + "-" + desde.getDate().toString().padStart(2, "0");
  let hasta = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  let dHasta = hasta.getFullYear() + "-" + (hasta.getMonth() + 1) + "-" + hasta.getDate().toString().padStart(2, "0");

  const [selDesde, setDesde] = useState(dDesde);
  const [selHasta, setHasta] = useState(dHasta);

  // clear filter button
  const [showClear, setShowClear] = useState(false);

  const spec = {
    "openapi": "3.0.0",
    "info": {
      "title": "Desguace API",
      "description": "API para Desguaces",
      "version": "1"
    },
    "servers": [
      {
        "url": "https://api.desguaces.contafact.net/"
      },
      {
        "url": "https://api.contafact.net/"
      }
    ],
    "paths": {
      "/marcas": {
        "get": {
          "tags": [
            "marcas"
          ],
          "summary": "Obtiene todas las marcas disponibles.",
          "description": "Obtiene marcas de los coches.",
          "operationId": "getMarcas",
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/Marcas"
                  }
                }
              }
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/marcas/{id}": {
        "get": {
          "tags": [
            "marcas"
          ],
          "summary": "Obtiene una marca específica.",
          "description": "Obtiene la marca específica que coincide con la ID.",
          "parameters": [
            {
              "name": "id",
              "in": "path",
              "description": "ID de la marca",
              "required": true,
              "style": "simple",
              "explode": false,
              "schema": {
                "type": "integer",
                "format": "int64"
              }
            }
          ],
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "array",
                    "items": {
                      "$ref": "#/components/schemas/Marcas"
                    }
                  }
                }
              }
            },
            "400": {
              "description": "Invalid ID value"
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/modelos": {
        "get": {
          "tags": [
            "modelos"
          ],
          "summary": "Obtiene todos los modelos disponibles.",
          "description": "Obtiene todos los modelos disponibles.",
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "array",
                    "items": {
                      "$ref": "#/components/schemas/Modelos"
                    }
                  }
                }
              }
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/modelos/{id}": {
        "get": {
          "tags": [
            "modelos"
          ],
          "summary": "Encuentra un modelo según su id.",
          "description": "Obtiene el modelo específico por su id.",
          "parameters": [
            {
              "name": "id",
              "in": "path",
              "description": "ID del modelo.",
              "required": true,
              "style": "simple",
              "explode": false,
              "schema": {
                "type": "integer",
                "format": "int64"
              }
            }
          ],
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/Modelos"
                  }
                }
              }
            },
            "400": {
              "description": "Invalid ID supplied"
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/modelos/marca/{id}": {
        "get": {
          "tags": [
            "modelos"
          ],
          "summary": "Obtiene los modelos según una marca.",
          "description": "Según la ID de una marca, obtendremos sus modelos.",
          "operationId": "getModelosByMarca",
          "parameters": [
            {
              "name": "id",
              "in": "path",
              "description": "ID de la marca",
              "required": true,
              "style": "simple",
              "explode": false,
              "schema": {
                "type": "integer",
                "format": "int64"
              }
            }
          ],
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/Modelos"
                  }
                }
              }
            },
            "400": {
              "description": "Invalid ID supplied"
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/coches": {
        "get": {
          "tags": [
            "coches"
          ],
          "summary": "Obtenemos todos los coches disponibles.",
          "description": "Obtenemos todos los coches que han sido dados de alta.",
          "responses": {
            "200": {
              "description": "Operación correcta.",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "array",
                    "items": {
                      "$ref": "#/components/schemas/Coches"
                    }
                  }
                }
              }
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/coches/coche/{id}": {
        "get": {
          "tags": [
            "coches"
          ],
          "summary": "Obtiene un coche específico.",
          "description": "Obtiene los datos de un coche, que se ha dado de alta previamente, específico según la ID.",
          "parameters": [
            {
              "name": "id",
              "in": "path",
              "description": "ID del coche.",
              "required": true,
              "style": "simple",
              "explode": false,
              "schema": {
                "type": "integer",
                "format": "int64"
              }
            }
          ],
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "array",
                    "items": {
                      "$ref": "#/components/schemas/Coches"
                    }
                  }
                }
              }
            },
            "400": {
              "description": "Invalid tag value"
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/coches/despiece": {
        "get": {
          "tags": [
            "coches"
          ],
          "summary": "Obtiene todas las piezas desguazadas.",
          "description": "Obtiene las piezas que se han seleccionado previamente en cada coche.",
          "operationId": "getdespiece",
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/PiezasCoche"
                  }
                }
              }
            },
            "404": {
              "description": "Not found"
            }
          }
        },
        "post": {
          "tags": [
            "coches"
          ],
          "summary": "Guarda los datos de la pieza a desguazar.",
          "description": "Se guarda en la base de datos la información relacionada con la pieza del coche que ha sido seleccionada.",
          "operationId": "postdespiece",
          "requestBody": {
            "description": "Guarda la pieza del coche. Se guarda la id del coche, la referencia de la pieza, si está disponible o no (por defecto 1), tiempo de despiece, marca del coche, modelo del coche, localización (nave.estante.fila.profundiad)",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/Despiece"
                }
              }
            },
            "required": true
          },
          "responses": {
            "200": {
              "description": "Operación correcta"
            }
          }
        }
      },
      "/coches/despiece/{id}": {
        "get": {
          "tags": [
            "coches"
          ],
          "summary": "Obtiene piezas específicas según la ID.",
          "description": "Obtiene piezas según la ID que han sido desguazadas previamente.",
          "parameters": [
            {
              "name": "id",
              "in": "path",
              "description": "ID de la pieza",
              "required": true,
              "style": "simple",
              "explode": false,
              "schema": {
                "type": "integer",
                "format": "int64"
              }
            }
          ],
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/PiezasCoche"
                  }
                }
              }
            },
            "400": {
              "description": "Invalid tag value"
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/coches/alta": {
        "post": {
          "tags": [
            "coches"
          ],
          "summary": "Da de alta un nuevo coche.",
          "description": "Cuando se da de alta un coche, se utiliza este endpoint para guardar sus datos en la base de datos.",
          "operationId": "altaCoche",
          "requestBody": {
            "description": "Da de alta un coche. Se guarda la marca, su modelo, matrícula, fecha de matriculación, año del coche, kilometraje, combustible y cambio.",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/Alta"
                }
              }
            },
            "required": true
          },
          "responses": {
            "200": {
              "description": "Operación correcta"
            }
          }
        }
      },
      "/coches/imagen": {
        "post": {
          "tags": [
            "coches"
          ],
          "summary": "Guardamos la imagen del coche.",
          "description": "Se guarda la imagen en un fichero llamado uploads, dentro se crea una carpeta con un número (que es la id del coche) y dentro de esta otra carpeta, la imagen en formato jpg, jpeg, png, gif o jfif",
          "operationId": "imagenCoche",
          "requestBody": {
            "content": {
              "application/octet-stream": {
                "schema": {
                  "type": "string",
                  "format": "binary"
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Uploaded"
            }
          }
        }
      },
      "/uploadimg": {
        "post": {
          "tags": [
            "coches"
          ],
          "summary": "Guardamos la imagen del coche.",
          "description": "Se guarda la imagen en un fichero llamado uploads, dentro se crea una carpeta con un número (que es la id del coche) y dentro de esta otra carpeta la imagen en formato jpg, jpeg, png, gif o jfif",
          "requestBody": {
            "content": {
              "application/octet-stream": {
                "schema": {
                  "type": "string",
                  "format": "binary"
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Uploaded"
            }
          }
        }
      },
      "/piezas": {
        "get": {
          "tags": [
            "piezas"
          ],
          "summary": "Obtiene todas las piezas/artículos.",
          "description": "Obtiene todas las piezas/artículos.",
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "array",
                    "items": {
                      "$ref": "#/components/schemas/Piezas"
                    }
                  }
                },
                "application/xml": {
                  "schema": {
                    "type": "array",
                    "items": {
                      "$ref": "#/components/schemas/Piezas"
                    }
                  }
                }
              }
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/piezas/familias": {
        "get": {
          "tags": [
            "piezas"
          ],
          "summary": "Obtiene todas las familias.",
          "description": "Obtiene todas las familias disponibles de las piezas/artículos.",
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/Familias"
                  }
                }
              }
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/piezas/pieza/{id}": {
        "get": {
          "tags": [
            "piezas"
          ],
          "summary": "Obtiene una pieza/artículo específico según la ID.",
          "description": "",
          "parameters": [
            {
              "name": "id",
              "in": "path",
              "description": "ID de la pieza/artículo",
              "required": true,
              "style": "simple",
              "explode": false,
              "schema": {
                "type": "integer",
                "format": "int64"
              }
            }
          ],
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/Piezas"
                  }
                }
              }
            },
            "400": {
              "description": "Invalid ID value"
            },
            "404": {
              "description": "Not found"
            }
          }
        }
      },
      "/piezas/updateLoc": {
        "post": {
          "tags": [
            "piezas"
          ],
          "summary": "Actualiza la localización de una pieza/artículo",
          "description": "Actualizamos la base de datos con la nueva localización de la pieza/artículo",
          "requestBody": {
            "description": "Guardamos en base de datos la nueva localización del artículo pasándole la ID del coche desguazado y la localización con el formato 0.0.0.0, siendo el primer 0 la nave, el segundo 0 la fila, el tercer 0 el estante y el cuarto 0 la profundidad.",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UpdateLoc"
                }
              }
            },
            "required": true
          },
          "responses": {
            "200": {
              "description": "Operación correcta"
            }
          }
        }
      },
      "/uploadArtImg": {
        "post": {
          "tags": [
            "piezas"
          ],
          "summary": "Guardamos la imagen de la pieza a desguazar.",
          "description": "Se busca la carpeta correspondiente al coche, que está en uploads/{idcoche}, dentro de esta carpeta creamos otra llamada 'ref' y dentro de esta otra carpeta guardamos la imagen del artículo/pieza en formato jpg, jpeg, png, gif o jfif",
          "requestBody": {
            "content": {
              "application/octet-stream": {
                "schema": {
                  "type": "string",
                  "format": "binary"
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Uploaded"
            }
          }
        }
      }
    },
    "components": {
      "securitySchemes": {
        "ApiKeyAuth": {
          "type": "apiKey",
          "in": "header",
          "name": "X-Api-Key"
        }
      },
      "schemas": {
        "Marcas": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "id": {
              "type": "integer",
              "format": "int64",
              "example": 1
            },
            "label": {
              "type": "string",
              "example": "ALFA ROMEO"
            }
          },
          "xml": {
            "name": "marcas"
          }
        },
        "Modelos": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "example": "1"
            },
            "idmarca": {
              "type": "string",
              "example": "1"
            },
            "modelid": {
              "type": "string",
              "example": "186"
            },
            "name": {
              "type": "string",
              "example": "145"
            }
          },
          "xml": {
            "name": "modelos"
          }
        },
        "Coches": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "example": "1"
            },
            "idmarca": {
              "type": "string",
              "example": "1"
            },
            "modelid": {
              "type": "string",
              "example": "186"
            },
            "matricula": {
              "type": "string",
              "example": "ABCD 123"
            },
            "fecha": {
              "type": "string",
              "example": "2022-09-05"
            },
            "an": {
              "type": "string",
              "example": "1990"
            },
            "km": {
              "type": "string",
              "example": "143476"
            },
            "combustible": {
              "type": "string",
              "example": "0"
            },
            "cambio": {
              "type": "string",
              "example": "0"
            },
            "marcalabel": {
              "type": "string",
              "example": "ALFA ROMEO"
            },
            "modelname": {
              "type": "string",
              "example": "145"
            },
            "cantPiezas": {
              "type": "string",
              "example": "5"
            },
            "imagePath": {
              "type": "string",
              "example": "http://nombreservidor/uploads/1/1.jpg"
            }
          },
          "xml": {
            "name": "coches"
          }
        },
        "Despiece": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "idcoche": {
              "type": "string",
              "example": "1"
            },
            "ref": {
              "type": "string",
              "example": "1"
            },
            "disponible": {
              "type": "string",
              "example": "1"
            },
            "ts": {
              "type": "string",
              "example": "2022-09-05 15:08:23"
            },
            "idmarca": {
              "type": "string",
              "example": "1"
            },
            "modelid": {
              "type": "string",
              "example": "186"
            },
            "loc": {
              "type": "string",
              "example": "3.2.1.1"
            }
          },
          "xml": {
            "name": "despiece"
          }
        },
        "PiezasCoche": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "example": "1"
            },
            "idcoche": {
              "type": "string",
              "example": "1"
            },
            "ref": {
              "type": "string",
              "example": "1"
            },
            "disponible": {
              "type": "string",
              "example": "1"
            },
            "ts": {
              "type": "string",
              "example": "2022-09-05 15:08:23"
            },
            "idmarca": {
              "type": "string",
              "example": "1"
            },
            "modelid": {
              "type": "string",
              "example": "186"
            },
            "loc": {
              "type": "string",
              "example": "3.2.1.1"
            },
            "familia": {
              "type": "string",
              "example": "ALUMBRADO"
            },
            "articulo": {
              "type": "string",
              "example": "FARO ANTINIEBLA DERECHO"
            },
            "matricula": {
              "type": "string",
              "example": "ABCD 123"
            },
            "marcalabel": {
              "type": "string",
              "example": "ALFA ROMEO"
            },
            "modelname": {
              "type": "string",
              "example": "145"
            },
            "imagePath": {
              "type": "string",
              "example": "http://nombreservidor/uploads/1/1.jpg"
            }
          },
          "xml": {
            "name": "piezascoche"
          }
        },
        "Piezas": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "example": "1"
            },
            "familia": {
              "type": "string",
              "example": "ALUMBRADO"
            },
            "articulo": {
              "type": "string",
              "example": "FARO ANTINIEBLA DERECHO"
            }
          },
          "xml": {
            "name": "piezas"
          }
        },
        "Alta": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "marca": {
              "type": "string",
              "example": "1"
            },
            "modelo": {
              "type": "string",
              "example": "1"
            },
            "mat": {
              "type": "string",
              "example": "ABCD 123"
            },
            "fecha": {
              "type": "string",
              "example": "2010-04-01"
            },
            "an": {
              "type": "string",
              "example": "2010"
            },
            "km": {
              "type": "string",
              "example": "121321"
            },
            "comb": {
              "type": "string",
              "example": "0"
            },
            "camb": {
              "type": "string",
              "example": "0"
            }
          },
          "xml": {
            "name": "alta"
          }
        },
        "Familias": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "example": "1"
            },
            "familia": {
              "type": "string",
              "example": "ALUMBRADO"
            }
          },
          "xml": {
            "name": "familias"
          }
        },
        "UpdateLoc": {
          "required": [
            "id"
          ],
          "type": "object",
          "properties": {
            "idcochedet": {
              "type": "string",
              "example": "1"
            },
            "loc": {
              "type": "string",
              "example": "1"
            }
          },
          "xml": {
            "name": "updateloc"
          }
        }
      }
    },
    "security": [
      {
        "ApiKeyAuth": []
      }
    ]
  };

  const specConectores = {
    "openapi": "3.0.0",
    "info": {
      "title": "Conectores",
      "description": "Conectores",
      "version": "1.0.0"
    },
    "servers": [
      {
        "url": "https://api.contafact.net"
      }
    ],
    "paths": {
      "/clients": {
        "get": {
          "tags": [
            "conectores"
          ],
          "summary": "Obtiene clientes",
          "description": "Según el usuario que ha iniciado sesión obtenemos clientes.",
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/ConectorClientsProv"
                  }
                }
              }
            }
          }
        }
      },
      "/proveedors": {
        "get": {
          "tags": [
            "conectores"
          ],
          "summary": "Obtiene proveedores",
          "description": "Según el usuario que ha iniciado sesión obtenemos proveedores.",
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/ConectorClientsProv"
                  }
                }
              }
            }
          }
        }
      },
      "/articles": {
        "get": {
          "tags": [
            "conectores"
          ],
          "summary": "Obtiene artículos",
          "description": "Según el usuario que ha iniciado sesión obtenemos artículos.",
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/ConectorArticles"
                  }
                }
              }
            }
          }
        }
      },
      "/articles/import": {
        "post": {
          "tags": [
            "conectores"
          ],
          "summary": "Importa datos de un fichero excel",
          "description": "Si la estructura es correcta, se insertarán los nuevos datos de artículos encontrados en el excel.",
          "responses": {
            "200": {
              "description": "Se importa y procesa el excel correctamente"
            }
          }
        }
      },
      "/articles/export": {
        "get": {
          "tags": [
            "conectores"
          ],
          "summary": "Export de excel con artículos",
          "description": "Exporta un excel con los artículos según el usuario que ha iniciado sesión.",
          "responses": {
            "200": {
              "description": "Descarga de excel correcta."
            }
          }
        }
      },
      "/comptes": {
        "get": {
          "tags": [
            "conectores"
          ],
          "summary": "Obtiene las cuentas",
          "description": "Según el usuario que ha iniciado sesión obtenemos las cuentas.",
          "parameters": [
            {
              "in": "query",
              "name": "desde",
              "schema": {
                "type": "integer"
              },
              "required": true,
              "description": "fecha desde"
            },
            {
              "in": "query",
              "name": "hasta",
              "schema": {
                "type": "string"
              },
              "required": true,
              "description": "fecha hasta"
            },
            {
              "in": "query",
              "name": "year",
              "schema": {
                "type": "string"
              },
              "required": true,
              "description": "ejercicio"
            },
            {
              "in": "query",
              "name": "ct",
              "schema": {
                "type": "string"
              },
              "required": true,
              "description": "ct"
            }
          ],
          "responses": {
            "200": {
              "description": "Operación correcta",
              "content": {
                "application/json": {
                  "schema": {
                    "$ref": "#/components/schemas/ConectorComptes"
                  }
                }
              }
            }
          }
        }
      }
    },
    "components": {
      "securitySchemes": {
        "ApiKeyAuth": {
          "type": "apiKey",
          "in": "header",
          "name": "X-Api-Key"
        }
      },
      "schemas": {
        "ConectorClientsProv": {
          "required": [
            "desde",
            "hasta",
            "year",
            "ct"
          ],
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "example": 1
            },
            "nombre": {
              "type": "string",
              "example": "EMPRESA SL"
            },
            "nif": {
              "type": "string",
              "example": "A12345678"
            },
            "direccion": {
              "type": "string",
              "example": "BEGONIA"
            },
            "cp": {
              "type": "string",
              "example": "28000"
            },
            "poblacion": {
              "type": "string",
              "example": "HOSPITALET"
            },
            "provincia": {
              "type": "string",
              "example": "BARCELONA"
            },
            "telefon": {
              "type": "string",
              "example": null
            },
            "mobil": {
              "type": "string",
              "example": null
            },
            "fax": {
              "type": "string",
              "example": null
            },
            "email": {
              "type": "string",
              "example": null
            },
            "contact": {
              "type": "string",
              "example": null
            },
            "banca": {
              "type": "string",
              "example": null
            },
            "oficina": {
              "type": "string",
              "example": null
            },
            "dc": {
              "type": "string",
              "example": null
            },
            "cuenta": {
              "type": "string",
              "example": ""
            },
            "iban": {
              "type": "string",
              "example": ""
            },
            "bic": {
              "type": "string",
              "example": ""
            },
            "cpais": {
              "type": "string",
              "example": "ES"
            },
            "subcta": {
              "type": "string",
              "example": 7050000000
            },
            "cont": {
              "type": "string",
              "example": 4300000000
            },
            "fpago": {
              "type": "string",
              "example": 22
            },
            "ziua1": {
              "type": "string",
              "example": 0
            },
            "ziua2": {
              "type": "string",
              "example": 0
            },
            "notas": {
              "type": "string",
              "example": null
            }
          }
        },
        "ConectorArticles": {
          "required": [
            "desde",
            "hasta",
            "year",
            "ct"
          ],
          "type": "object",
          "properties": {
            "id": {
              "type": "string",
              "example": 1
            },
            "cod": {
              "type": "string",
              "example": "8001002005634639"
            },
            "codb": {
              "type": "string",
              "example": "8001002003004009"
            },
            "articulo": {
              "type": "string",
              "example": "Artículo gran reserva 40"
            },
            "familia": {
              "type": "string",
              "example": "cava"
            },
            "pvd": {
              "type": "string",
              "example": 0
            },
            "iva": {
              "type": "string",
              "example": 21
            },
            "irpf": {
              "type": "string",
              "example": 0
            },
            "compra": {
              "type": "string",
              "example": ""
            },
            "venta": {
              "type": "string",
              "example": ""
            },
            "pvp": {
              "type": "string",
              "example": 10.08
            },
            "piva": {
              "type": "string",
              "example": 12.2
            },
            "pvp1": {
              "type": "string",
              "example": 0
            },
            "pvp2": {
              "type": "string",
              "example": 0
            },
            "pvp3": {
              "type": "string",
              "example": 0
            },
            "pvp4": {
              "type": "string",
              "example": 0
            },
            "pvp5": {
              "type": "string",
              "example": 0
            },
            "pvp6": {
              "type": "string",
              "example": 0
            },
            "pvp7": {
              "type": "string",
              "example": 0
            },
            "pvp8": {
              "type": "string",
              "example": 0
            },
            "pvp9": {
              "type": "string",
              "example": 0
            },
            "ibee": {
              "type": "string",
              "example": 0
            },
            "lit": {
              "type": "string",
              "example": 0
            }
          }
        },
        "ConectorComptes": {
          "required": [
            "desde",
            "hasta",
            "year",
            "ct"
          ],
          "type": "object",
          "properties": {
            "cod": {
              "type": "string",
              "example": 1130000000
            },
            "descripcion": {
              "type": "string",
              "example": "CAPITAL SOCIAL"
            },
            "cif": {
              "type": "string",
              "example": ""
            }
          }
        }
      }
    },
    "security": [
      {
        "ApiKeyAuth": []
      }
    ]
  }

  useEffect(() => {
    
    if (!token) {
      return;
    }

    setNewApiKey(token.api_key);

    if (modalClaveCb) {
      setShow(modalClaveCb);
    }

    getUserList(token).then((users) => {
      if (users !== undefined) {
        setUsers(users);
      }
    });

    getSessionLogs(token, dDesde, dHasta).then((logs) => {
      if (logs !== undefined) {
        setLogs(logs);
        setOrigLogs(logs);
      }
    });

    getVersionLogs(token).then((version) => {
      if (version !== undefined) {
        setVersion(version);
      }
    });
  }, [token, modalClaveCb]);

  useEffect(() => {
    if (selDesde === '' && selHasta === '') {
      setShowClear(false);
      setLogs(origLogs);
    } else {
      getSessionLogs(token, selDesde, selHasta).then((logs) => {
        if (logs !== undefined) {
          setLogs(logs);
          setOrigLogs(logs);
          setShowClear(true);
        }
      });

      /*
      if (selDesde !== '') {
        origData = origData.filter(el => el.logintime >= selDesde);
      }

      if (selHasta !== '') {
        origData = origData.filter(el => el.logintime <= selHasta);
      }
      */
    }
  }, [selDesde, selHasta]);

  // limpieza filtros
  const clearFilters = () => {
    // original data
    setLogs(logs);

    // default value of selects
    setDesde('');
    setHasta('');

    // btn clear filters
    setShowClear(false);
  }

  const handleActualPass = (e) => {
    e.preventDefault();
    setActualPass(e.target.value);
  }

  const handlePass = (e) => {
    e.preventDefault();
    setPass(e.target.value);
  }

  const handleRetypePass = (e) => {
    e.preventDefault();
    setRetypePass(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (pass.length < 6) {
      setMinCharsError(true);
    } else {
      setMinCharsError(false);
    }

    if (pass === retypePass) {
      setMatchError(false);
    } else {
      setMatchError(true);
    }

    checkPass(token, actualPass).then((result) => {
      setActualPassError(result);

      if (!result && !minCharsError && !matchError) {
        changePass({
          pass: pass,
          nif: token.xnif
        });
        setShow(false);
        guardarClave();
      }
    });
  }

  const handleGenApiKey = async () => {
    const newToken = await genApiKey({
      nif: token.xnif,
      master: token.xmaster
    });
    setNewApiKey(newToken);
  }


  function arrayObjToCsv(ar) {
    //comprobamos compatibilidad
    if (window.Blob && (window.URL || window.webkitURL)) {
      var contenido = "",
        d = new Date(),
        blob,
        reader,
        save,
        clicEvent;
      //creamos contenido del archivo
      for (var i = 0; i < ar.length; i++) {
        //construimos cabecera del csv
        if (i === 0) contenido += Object.keys(ar[i]).join(";") + "\n";
        //resto del contenido
        contenido += Object.keys(ar[i]).map(function (key) {
          return ar[i][key];
        }).join(";") + "\n";
      }
      //creamos el blob
      blob = new Blob(["\ufeff", contenido], { type: 'text/csv' });
      //creamos el reader
      reader = new FileReader();
      reader.onload = function (event) {
        //escuchamos su evento load y creamos un enlace en dom
        save = document.createElement('a');
        save.href = event.target.result;
        save.target = '_blank';
        //aquí le damos nombre al archivo
        save.download = "desguaces" + d.getDate() + "" + (d.getMonth() + 1) + "" + d.getFullYear() + "_" + d.getHours() + "" + d.getMinutes() + ".csv";
        try {
          //creamos un evento click
          clicEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });
        } catch (e) {
          //si llega aquí es que probablemente implemente la forma antigua de crear un enlace
          clicEvent = document.createEvent("MouseEvent");
          clicEvent.initEvent('click', true, true);
        }
        //disparamos el evento
        save.dispatchEvent(clicEvent);
        //liberamos el objeto window.URL
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
      //leemos como url
      reader.readAsDataURL(blob);
    } else {
      //el navegador no admite esta opción
      alert("Su navegador no permite esta acción");
    }
  };

  function downloadCSV(array) {
    const link = document.createElement('a');
    //let csv = convertArrayOfObjectsToCSV(array);
    let csv = arrayObjToCsv(array);
    if (csv == null) return;

    let d = new Date();
    let date = "desguaces_" + d.getMonth() + 1 + "" + d.getDate() + "" + d.getFullYear();
    const filename = date + '.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(logs)} />, [logs]);

  // botón limpiar filtros
  const ClearFiltersBtn = () => {
    return (
      <Button variant="danger" className="btn btn-sm btn-primary text-white ms-2" style={{ width: "auto" }} onClick={clearFilters}>
        <i className="mdi mdi-delete"></i> Limpiar filtro
      </Button>
    );
  }

  const handleChangeDefinition = (e) => {
    if (e !== null) {
      try {
        setDefinition(e);
      } catch (error) {
        setDefinition({ value: 0, label: 'Origen' });
      }
    } else {
      setDefinition({ value: 0, label: 'Origen' });
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}
        dialogClassName="modal-xl"
        backdrop="static"
        keyboard={false}
        aria-labelledby="info-header-modalLabel"
        style={{ paddingLeft: 0, margin: 0 }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Configuración</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="nav nav-pills nav-fill mt-4" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab" href="#claveacceso" role="tab">
                <span>Clave de acceso</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#api" role="tab">
                <span>Generar clave API</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#docapi" role="tab">
                <span>Conectores</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#userlog" role="tab">
                <span>Usuarios</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#sesionlog" role="tab">
                <span>Sesiones</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#versionlog" role="tab">
                <span>Versiones</span>
              </a>
            </li>
          </ul>

          <div className="tab-content border mt-2">
            <div className="tab-pane active p-3" id="claveacceso" role="tabpanel">
              <div className="row">
                <form onSubmit={handleSubmit} className="ps-3 pe-3">
                  <div className="mb-3">
                    <label htmlFor="actualPass">Confirma tu contraseña actual</label>
                    <input className="form-control" type="password" id="actualPass" name="actualPass" onChange={handleActualPass} value={actualPass} />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="pass">Contraseña</label>
                    <input className="form-control" type="password" id="pass" required="" min={6} placeholder="Al menos 6 caracteres" onChange={handlePass} value={pass} />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="retypePass">Confirma tu contraseña</label>
                    <input className="form-control" type="password" required="" id="retypePass" onChange={handleRetypePass} value={retypePass} />
                  </div>

                  <div className="mb-3 text-center">
                    <button className="btn btn-light-info text-info font-weight-medium" type="submit">
                      Cambiar clave
                    </button>
                  </div>

                  {actualPassError ? (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                      Contraseña actual incorrecta.
                    </div>
                  ) : (
                    null
                  )}

                  {minCharsError ? (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                      La contraseña tiene que tener al menos 6 caracteres.
                    </div>
                  ) : (
                    null
                  )}

                  {matchError ? (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                      Las contraseñas tienen que coincidir.
                    </div>
                  ) : (
                    null
                  )}
                </form>

              </div>
            </div>
            <div className="tab-pane p-3" id="api" role="tabpanel">
              <div className="row">
                <div className="col-md-8">
                  <form className="mt-3">
                    <div className="form-group">
                      <details>
                        <summary>Mostrar clave</summary>
                        <textarea className="form-control" rows="3" value={newApiKey} readOnly />
                      </details>

                      <div className="mt-3 text-center">
                        <button className="btn btn-light-info text-info font-weight-medium" type="button" onClick={handleGenApiKey}>
                          Generar nueva clave API
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="tab-pane p-3" id="docapi" role="tabpanel">
              <label>Seleccionar definición: </label><Select defaultValue={"{value: 0, label: 'Origen'}"} options={optDefinitions} onChange={handleChangeDefinition} value={definition} />

              {definition.value === 0 ? <SwaggerUI spec={spec} /> : <SwaggerUI spec={specConectores} />}
            </div>
            <div className="tab-pane p-3" id="userlog" role="tabpanel">
              <div className="row">
                <DataTableBase
                  columns={columnsUsers}
                  data={users}
                />
              </div>
            </div>
            <div className="tab-pane p-3" id="sesionlog" role="tabpanel">
              {showClear ? <ClearFiltersBtn /> : null}

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-1">
                    <label>Desde</label>
                    <input type="date" className="form-control" onChange={(e) => setDesde(e.target.value)} value={selDesde} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-1">
                    <label>Hasta</label>
                    <input type="date" className="form-control" onChange={(e) => setHasta(e.target.value)} value={selHasta} />
                  </div>
                </div>

                <DataTableBase
                  columns={columnsSessions}
                  data={logs}
                  actions={actionsMemo}
                />
              </div>
            </div>
            <div className="tab-pane p-3" id="versionlog" role="tabpanel">
              <div className="row">
                <DataTableBase
                  columns={columnsVersion}
                  data={version}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalConfig;