import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { getCoches, getMarcas, getModeloByMarca } from '../Data/data';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// opciones para combustible y cambio
const optCombustible = [
    { value: '0', label: 'Diesel' },
    { value: '1', label: 'Gasolina' },
    { value: '2', label: 'Eléctrico' },
    { value: '3', label: 'Híbrido' }
];
const optCambio = [
    { value: '0', label: 'Manual' },
    { value: '1', label: 'Automático' }
];

const OffCanvasFilters = ({ tokenLayout, carData }) => {
    // select options
    const [optMarcas, setOptMarcas] = useState([]);
    const [optModelos, setOptModelos] = useState([]);
    const [disabledModelos, setDisabledModelos] = useState(true);

    // select values
    const [selMarcas, setMarca] = useState('');
    const [selModelos, setModelo] = useState('');
    const [selCombustible, setCombustible] = useState('');
    const [selCambio, setCambio] = useState('');

    const [selDesde, setDesde] = useState('');
    const [selHasta, setHasta] = useState('');
    //const [selFecha, setFecha] = useState('');
    const [selAn, setAn] = useState('');
    const [selKm, setKm] = useState('');

    // data list
    const [jsonCars, setJsonCars] = useState([]);

    // clear filter button
    const [showClear, setShowClear] = useState(false);

    // fetch data
    useEffect(() => {
        getCoches(tokenLayout).then((cars) => {
            if (cars !== undefined) {
                setJsonCars(cars);
            }
        });

        getMarcas(tokenLayout).then((marcas) => {
            if (marcas !== undefined) {
                let newArr = [];
                marcas.forEach(marca => {
                    newArr.push({ value: marca.id, label: marca.label });
                });
                setOptMarcas(newArr);
            }
        });
    }, [tokenLayout]);

    // controlador select marcas
    const handleMarcas = (e) => {
        if (e !== null) {
            try {
                setMarca(e);
                getModeloByMarca(tokenLayout, e.value).then((modelos) => {
                    if (modelos !== false) {
                        let newArr = [];
                        modelos.forEach(modelo => {
                            newArr.push({ value: modelo.modelid, label: modelo.name });
                        });
                        setOptModelos(newArr);
                        setDisabledModelos(false);
                    } else {
                        setDisabledModelos(true);
                    }
                });
            } catch (error) {
                setMarca('');
            }
        } else {
            setMarca('');
            setModelo('');
            setDisabledModelos(true);
        }
    }

    // controlador select modelos
    const handleModelos = (e) => {
        if (e !== null) {
            try {
                setModelo(e);
            } catch (error) {
                setModelo('');
            }
        } else {
            setModelo('');
        }
    }

    // controlador select combustible
    const handleCombustible = (e) => {
        if (e !== null) {
            try {
                setCombustible(e);
            } catch (error) {
                setCombustible('');
            }
        } else {
            setCombustible('');
        }
    }

    // controlador select cambio
    const handleCambio = (e) => {
        if (e !== null) {
            try {
                setCambio(e);
            } catch (error) {
                setCambio('');
            }
        } else {
            setCambio('');
        }
    }

    // cambios en selectores
    useEffect(() => {
        let origData = jsonCars;
        if (selMarcas === '' && selModelos === '' && selCombustible === '' && selCambio === '' && selDesde === '' && selHasta === '' && selAn === '' && selKm === '') {
            setShowClear(false);
            carData(jsonCars);
        } else {
            if (selMarcas !== '') {
                origData = origData.filter(el => el.idmarca === selMarcas.value);
            }

            if (selModelos !== '') {
                origData = origData.filter(el => el.modelid === selModelos.value);
            }

            if (selCombustible !== '') {
                origData = origData.filter(el => el.combustible === selCombustible.value);
            }

            if (selCambio !== '') {
                origData = origData.filter(el => el.cambio === selCambio.value);
            }

            /*
            if (selDesde !== '' && selHasta !== '') {
                origData = origData.filter(el => el.fecha === selFecha);
            }
            */

            if (selDesde !== '') {
                origData = origData.filter(el => el.fecha >= selDesde);
            }

            if (selHasta !== '') {
                origData = origData.filter(el => el.fecha <= selHasta);
            }

            /*
            if (selFecha !== '') {
                origData = origData.filter(el => el.fecha === selFecha);
            }
            */

            if (selKm !== '') {
                origData = origData.filter(el => el.km === selKm);
            }

            if (selAn !== '') {
                origData = origData.filter(el => el.an === selAn);
            }

            setShowClear(true);
            carData(origData);
        }
    }, [selMarcas, selModelos, selCombustible, selCambio, selDesde, selHasta, selAn, selKm]);

    // limpieza de selects
    const clearFilters = () => {
        // original data
        carData(jsonCars);

        // default value of selects
        setMarca('');
        setModelo('');
        setCombustible('');
        setCambio('');
        setDesde('');
        setHasta('');
        setKm('');
        setAn('');

        // btn clear filters
        setShowClear(false);
    }


    const tooltipFiltro = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Abrir filtros
        </Tooltip>
    );

    const tooltipLimpiarFiltro = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Limpiar filtros
        </Tooltip>
    );

    // botón limpiar filtros
    const ClearFiltersBtn = () => {
        return (
            <OverlayTrigger
                placement="top"
                overlay={tooltipLimpiarFiltro}>
                <Button variant="danger" className="btn btn-sm btn-primary text-white ms-2" onClick={clearFilters}>
                    <i className="mdi mdi-delete"></i>
                </Button>
            </OverlayTrigger>
        );
    }


    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={tooltipFiltro}>
                <Button variant="primary" className="btn btn-sm btn-primary text-white" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilter">
                    <i className="mdi mdi-filter"></i>
                </Button>
            </OverlayTrigger>
            {showClear ? <ClearFiltersBtn /> : null}

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasFilter" data-bs-scroll="true" aria-labelledby="offcanvasFilterLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasFilterLabel">Filtros</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" tabIndex="-1"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-1">
                                <label>Marcas</label>
                                <Select options={optMarcas} isClearable='true' onChange={handleMarcas} value={selMarcas} placeholder="Marcas" />
                            </div>
                            <div className="mb-1">
                                <label>Modelos</label>
                                <Select options={optModelos} isClearable='true' onChange={handleModelos} value={selModelos} isDisabled={disabledModelos} placeholder="Modelos" />
                            </div>
                            <div className="mb-1">
                                <label>Combustible</label>
                                <Select options={optCombustible} isClearable='true' onChange={handleCombustible} value={selCombustible} placeholder="Combustible" />
                            </div>
                            <div className="mb-1">
                                <label>Cambio</label>
                                <Select options={optCambio} isClearable='true' onChange={handleCambio} value={selCambio} placeholder="Cambio" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label>Fecha de matriculación</label>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-1">
                                <label>Desde</label>
                                <input type="date" className="form-control" onChange={(e) => setDesde(e.target.value)} value={selDesde} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-1">
                                <label>Hasta</label>
                                <input type="date" className="form-control" onChange={(e) => setHasta(e.target.value)} value={selHasta} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-1">
                                <label>Año</label>
                                <input type="number" min="1900" max="2099" step="1" className="form-control" onChange={(e) => setAn(e.target.value)} />
                            </div>
                            <div className="mb-1">
                                <label>Km</label>
                                <input type="number" className="form-control" onChange={(e) => setKm(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OffCanvasFilters;