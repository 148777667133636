import React, { useEffect, useState } from 'react';
import { getCoches } from '../../Data/data';
import { AlignJustify, Grid, AlertCircle } from 'react-feather';
import DataTableBase from '../../Datatable/Datatable';
import './coches.css';

// columnas para el datatable
const columns = [
    {
        name: 'Imagen',
        maxWidth: '85px',
        minWidth: '85px',
        selector: row => row.imagePath ?
            (
                <img src={row.imagePath} alt='imagen de coche' className="rounded" style={{ height: '40px', width: '40px', objectFit: 'cover' }}></img>
            ) : (
                <div className="bg-light-danger text-danger d-inline-block py-3 rounded d-flex align-items-center justify-content-center me-2" style={{ width: '40px', height: '40px' }}>
                    <AlertCircle size={'15px'} />
                </div>
            )
    },
    {
        name: 'Matrícula',
        selector: row => row.matricula,
        sortable: true,
    },
    {
        name: 'Marca',
        selector: row => row.marcalabel,
        sortable: true,
    },
    {
        name: 'Modelo',
        selector: row => row.modelname,
        sortable: true,
    },
    {
        name: 'Combustible',
        selector: row => row.combLabel,
        sortable: true,
        maxWidth: '120px',
        minWidth: '120px',
        conditionalCellStyles: [
            {
                when: row => row.combustible === '0',
                style: {
                    backgroundColor: 'rgba(50, 50, 50, 0.8)',
                    color: 'white',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
            {
                when: row => row.combustible === '1',
                style: {
                    backgroundColor: 'rgba(6, 125, 40, 0.62)',
                    color: 'white',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
            {
                when: row => row.combustible === '2',
                style: {
                    backgroundColor: 'rgba(113, 196, 10, 0.62)',
                    color: 'white',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
            {
                when: row => row.combustible === '3',
                style: {
                    backgroundColor: 'rgba(10, 183, 196, 0.62)',
                    color: 'white',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
        ],
    },
    {
        name: 'Cambio',
        selector: row => row.cambioLabel,
        sortable: true,
        maxWidth: '120px',
        minWidth: '120px',
    },
    {
        name: 'Km',
        selector: row => row.km,
        sortable: true,
    },
    {
        name: 'Año',
        selector: row => row.an,
        sortable: true,
        maxWidth: '90px',
        minWidth: '90px',
    },
    {
        name: 'Fecha matrícula',
        selector: row => row.fecha,
        sortable: true,
    },
    {
        name: 'Nº de piezas',
        selector: row => row.cantPiezas,
        sortable: true,
        conditionalCellStyles: [
            {
                when: row => row.cantPiezas <= 0,
                style: {
                    backgroundColor: 'rgba(255, 51, 51, 0.7)',
                    color: 'white',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
        ],
        maxWidth: '130px',
        minWidth: '130px',
    },
];

function setLabels(arr) {
    let setArray = arr.slice();
    for (let i = 0; i < arr.length; i++) {
        if (setArray[i].cambio === '0') {
            setArray[i]['cambioLabel'] = 'Manual';
        } else {
            setArray[i]['cambioLabel'] = 'Automático';
        }

        if (setArray[i].combustible === '0') {
            setArray[i]['combLabel'] = 'Diesel';
        } else if (setArray[i].combustible === '1') {
            setArray[i]['combLabel'] = 'Gasolina';
        } else if (setArray[i].combustible === '2') {
            setArray[i]['combLabel'] = 'Eléctrico';
        } else if (setArray[i].combustible === '3') {
            setArray[i]['combLabel'] = 'Híbrido';
        }
    }
    return setArray;
}

const Coches = ({ tokenApp, filteredData, searchData, modalCallback, reloadCb }) => {
    // data original sin filtrar
    const [origData, setOrigData] = useState([]);

    // copia de filtros para no afectar la lista de coches al utilizar el buscador y filtros a la vez
    const [filterCars, setFilterCars] = useState([]);

    // para mostrar los coches en datatable y filtraje
    const [cars, setCars] = useState([]);

    // loading para los coches
    const [cLoading, setCLoading] = useState(true);

    const [iLoading, setILoading] = useState(true);

    // cambio de vista por grid o datatable
    const [view, setView] = useState(false);

    // fetch de coches
    useEffect(() => {
        setCLoading(true);

        if (!tokenApp) {
            return;
        }

        document.title = 'Desguaces - Coches';
        getCoches(tokenApp).then((coches) => {
            if (coches !== undefined) {
                let newCoches = setLabels(coches);
                setOrigData(newCoches);
                setCars(newCoches);
            }
        }).finally(() => {
            setCLoading(false);
        });

    }, [tokenApp, reloadCb]);


    // cuando hayan cambios en el buscador, se construye una string con todos los datos posibles a buscar
    // por otro lado, se separa cada palabra escrita en el buscador y se forma un regex
    // se compara cada regex con las strings de los artículos y solo nos quedamos con los artículos que coincidan
    useEffect(() => {
        if (searchData !== '') {
            let strSearch = searchData.toLowerCase();
            strSearch = strSearch.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
            let splitStr = strSearch.split(' ');
            let regexStr = '';
            for (let i = 0; i < splitStr.length; i++) {
                regexStr += "(?=.*" + splitStr[i] + ")";
            }
            regexStr += '.+';
            let re = new RegExp(regexStr, 'g');
            let arrMatches = [];
            if (filterCars.length > 0) {
                filterCars.forEach(car => {
                    let str = '';
                    try {
                        str = car.matricula.toLowerCase() + " " + car.marcalabel.toLowerCase() + " " + car.modelname.toLowerCase() + " " + car.cambioLabel.toLowerCase() + " " + car.combLabel.toLowerCase();
                    } catch (e) {
                        console.log(e);
                        str = '';
                    }
                    while (!!(re.exec(str))) {
                        arrMatches.push(car);
                    }
                });
                setCars(arrMatches);
            } else {
                origData.forEach(car => {
                    let str
                    try {
                        str = car.matricula.toLowerCase() + " " + car.marcalabel.toLowerCase() + " " + car.modelname.toLowerCase() + " " + car.cambioLabel.toLowerCase() + " " + car.combLabel.toLowerCase();
                    } catch (e) {
                        console.log(e);
                        str = '';
                    }
                    while (!!(re.exec(str))) {
                        arrMatches.push(car);
                    }
                });
                setCars(arrMatches);
            }
        } else {
            if (filteredData.length > 0) {
                setCars(filterCars);
            } else {
                setCars(origData);
            }
        }
    }, [searchData, filterCars]);

    // cuando hayan cambios en filtros se aplican en coches
    useEffect(() => {
        let newCoches = setLabels(filteredData);
        setFilterCars(newCoches);
        setCars(newCoches);
    }, [filteredData]);

    // obtenemos un badge dependiendo del combustible
    function getCombustibleDot(combustible) {
        switch (parseInt(combustible)) {
            case 0:
                return (
                    <div className="list-inline-item" >
                        <span className="badge rounded-pill" style={{ backgroundColor: 'black' }}> <svg width="10px" height="10px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 64C32 28.7 60.7 0 96 0H256c35.3 0 64 28.7 64 64V256h8c48.6 0 88 39.4 88 88v32c0 13.3 10.7 24 24 24s24-10.7 24-24V222c-27.6-7.1-48-32.2-48-62V96L384 64c-8.8-8.8-8.8-23.2 0-32s23.2-8.8 32 0l77.3 77.3c12 12 18.7 28.3 18.7 45.3V168v24 32V376c0 39.8-32.2 72-72 72s-72-32.2-72-72V344c0-22.1-17.9-40-40-40h-8V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64zM96 80v96c0 8.8 7.2 16 16 16H240c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16z" fill="#ffffff"/></svg> <small>Diesel</small></span>
                    </div>
                );
            case 1:
                return (
                    <div className="list-inline-item">
                        <span className="badge rounded-pill" style={{ backgroundColor: 'darkgreen' }}> <svg width="10px" height="10px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 64C32 28.7 60.7 0 96 0H256c35.3 0 64 28.7 64 64V256h8c48.6 0 88 39.4 88 88v32c0 13.3 10.7 24 24 24s24-10.7 24-24V222c-27.6-7.1-48-32.2-48-62V96L384 64c-8.8-8.8-8.8-23.2 0-32s23.2-8.8 32 0l77.3 77.3c12 12 18.7 28.3 18.7 45.3V168v24 32V376c0 39.8-32.2 72-72 72s-72-32.2-72-72V344c0-22.1-17.9-40-40-40h-8V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64zM96 80v96c0 8.8 7.2 16 16 16H240c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16z" fill="#ffffff"/></svg> <small>Gasolina</small></span>
                    </div>
                );
            case 2:
                return (
                    <div className="list-inline-item">
                        <span className="badge rounded-pill" style={{ backgroundColor: 'chartreuse' }}> <svg width="10px" height="10px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 64C32 28.7 60.7 0 96 0H256c35.3 0 64 28.7 64 64V256h8c48.6 0 88 39.4 88 88v32c0 13.3 10.7 24 24 24s24-10.7 24-24V222c-27.6-7.1-48-32.2-48-62V96L384 64c-8.8-8.8-8.8-23.2 0-32s23.2-8.8 32 0l77.3 77.3c12 12 18.7 28.3 18.7 45.3V168v24 32V376c0 39.8-32.2 72-72 72s-72-32.2-72-72V344c0-22.1-17.9-40-40-40h-8V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64zM96 80v96c0 8.8 7.2 16 16 16H240c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16z" fill="#ffffff"/></svg> <small>Eléctrico</small></span>
                    </div>
                );
            case 3:
                return (
                    <div className="list-inline-item" >
                        <span className="badge rounded-pill" style={{ backgroundColor: 'darkturquoise' }}> <svg width="10px" height="10px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 64C32 28.7 60.7 0 96 0H256c35.3 0 64 28.7 64 64V256h8c48.6 0 88 39.4 88 88v32c0 13.3 10.7 24 24 24s24-10.7 24-24V222c-27.6-7.1-48-32.2-48-62V96L384 64c-8.8-8.8-8.8-23.2 0-32s23.2-8.8 32 0l77.3 77.3c12 12 18.7 28.3 18.7 45.3V168v24 32V376c0 39.8-32.2 72-72 72s-72-32.2-72-72V344c0-22.1-17.9-40-40-40h-8V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64zM96 80v96c0 8.8 7.2 16 16 16H240c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16z" fill="#ffffff"/></svg> <small>Híbrido</small></span>
                    </div>
                );
            default:
                break;
        }
    }

    // obtenemos un badge dependiendo del cambio
    function getCambioDot(cambio) {
        switch (parseInt(cambio)) {
            case 0:
                return (
                    <div className="list-inline-item">
                        <span className="badge rounded-pill font-weight-medium bg-light-danger text-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M8 5H4V2h4v3M4 22h4v-3H4v3M14 2h-4v3h4V2m-4 20h4v-3h-4v3m6-20v3h4V2h-4m1 9h-4V7h-2v4H7V7H5v10h2v-4h4v4h2v-4h6V7h-2v4Z" /></svg>Manual
                        </span>
                    </div>
                );
            case 1:
                return (
                    <div className="list-inline-item">
                        <span className="badge rounded-pill font-weight-medium bg-light-primary text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M8 5H4V2h4v3M4 22h4v-3H4v3M14 2h-4v3h4V2m-4 20h4v-3h-4v3m6-20v3h4V2h-4m1 9h-4V7h-2v4H7V7H5v10h2v-4h4v4h2v-4h6V7h-2v4Z" /></svg>Automático
                        </span>
                    </div>
                );
            default:
                break;
        }
    }

    // para cambiar la vista entre grid y datatable
    const handleView = () => {
        setView(!view);
    }

    // abre el modal al clicar sobre alguna fila de la datatable
    const handleRowClicked = (e) => {
        modalCallback(e);
    }

    // pasamos el enlace de la imagen para formar un img del coche
    const Image = ({path}) => {
        return (
            <img className={`card-img-top ${iLoading ? 'smooth': 'smooth loaded'}`} src={path} alt="Card cap" style={{ height: '190px', objectFit: 'cover' }} onLoad={() => setILoading(false)} />
        );
    }

    const CarList = () => {
        // contenido de los coches, imagen, título, detalles
        // si el coche no tiene path de imagen, cargamos una señal de alerta
        const CardContent = (car) => {
            return (
                <>
                    <a href='#/' onClick={() => modalCallback(car)} className="stretched-link"> </a>

                    <div>
                        {car.imagePath !== '' ? (
                            <Image path={car.imagePath} />
                        ) : (
                            <div className="bg-light-danger text-danger d-inline-block py-3 rounded d-flex align-items-center justify-content-center mb-1" style={{ height: '180px', width: '100%' }}>
                                <AlertCircle size={'30%'} />
                            </div>
                        )}
                    </div>

                    <div className="card-body">
                        <h6 className="card-title">{car.matricula ? car.matricula : ' '} {<span className="badge ms-auto bg-light-secondary text-secondary">{car.cantPiezas}</span>} </h6>
                        <div className="d-flex justify-content-between flex-column">
                            <small className="card-text">{car.marcalabel}</small>
                            <small className="card-text">{car.modelname}</small>
                            <div className="list-inline d-flex justify-content-between flex-row mt-2">
                                {getCombustibleDot(car.combustible)}
                                {getCambioDot(car.cambio)}
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        // borde de la carta para saber si el coche tiene despiece o no
        const CardBorder = (car) => {
            if (car.cantPiezas > 0) {
                return (
                    <div className="card rounded-3 card-hover w-100 overflow-hidden" style={{ height: '330px', cursor: 'pointer' }}>
                        <CardContent {...car} />
                    </div>
                );

            } else {
                return (
                    <div className="card border-bottom border-danger rounded-3 card-hover w-100 overflow-hidden" style={{ height: '330px', cursor: 'pointer' }}>
                        <CardContent {...car} />
                    </div>
                );
            }
        }

        // para formar el grid en columnas
        const listCars = cars.map((car) =>
            <div key={car.id} className="col-lg-4 col-md-6 col-xl-2" >
                <CardBorder {...car} />
            </div>
        );

        // spinner al cargar coches
        function Loader() {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        // si al buscar o filtrar no hay coches encontrados
        function NoElements() {
            return (
                <div className="col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-row">
                                <div className="ms-2 align-self-center">
                                    <h3 className="mb-0">Sin resultados</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        // si está cargando, muestra el spinner
        if (!cLoading) {

            // si no hay coches es que no se ha encontrado ninguno con los filtros/buscador
            if (cars.length > 0) {

                // vista en grid o datatable
                if (!view) {
                    return (
                        <div className="row" >
                            <div className="d-flex justify-content-end mb-2 align-items-center">
                                <button className="btn btn-sm btn-secondary" onClick={handleView}><AlignJustify></AlignJustify></button>
                            </div>
                            {listCars}
                        </div>
                    );
                } else {
                    return (
                        <div className="row" >
                            <div className="d-flex justify-content-end mb-2 align-items-center">
                                <button className="btn btn-sm btn-secondary" onClick={handleView}><Grid></Grid></button>
                            </div>
                            <DataTableBase
                                columns={columns}
                                data={cars}
                                highlightOnHover
                                pointerOnHover
                                onRowClicked={handleRowClicked}
                            />
                        </div>
                    );
                }
            } else {
                return (
                    <NoElements />
                );
            }
        } else {
            return (
                <Loader />
            );
        }
    }

    return (
        <CarList />
    );
}


export default Coches;