import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getMarcas, getModeloByMarca, getApiUrl } from '../Data/data';
import Webcam from "react-webcam";
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { Camera, CameraOff } from 'react-feather';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Compressor from 'compressorjs';
const MySwal = withReactContent(Swal);
//const WebcamComponent = () => <Webcam />;


const optCombustible = [
    { value: '0', label: 'Diesel' },
    { value: '1', label: 'Gasolina' },
    { value: '2', label: 'Eléctrico' },
    { value: '3', label: 'Híbrido' }
];
const optCambio = [
    { value: '0', label: 'Manual' },
    { value: '1', label: 'Automático' }
];
const OffCanvasAlta = ({ tokenLayout, saveToParent }) => {
    // form select options
    const [optMarcas, setOptMarcas] = useState([]);
    const [optModelos, setOptModelos] = useState([]);
    const [disabledModelos, setDisabledModelos] = useState(true);

    // select value
    const [selMarca, setSelMarca] = useState(null);
    const [selModelo, setSelModelo] = useState(null);

    // form data
    const [mat, setMatricula] = useState('');
    const [fecha, setFecha] = useState('');
    const [an, setAn] = useState('');
    const [km, setKm] = useState('');
    const [comb, setComb] = useState(null);
    const [camb, setCamb] = useState(null);

    // image/camera
    const webcamRef = useRef(null);
    const [errorCam, setErrorCam] = useState();
    const [show, setShow] = useState(false);
    const [image, setImage] = useState(null);
    const [imgBlob, setImgBlob] = useState(null);
    const [imgSizeError, setImgSizeError] = useState(false);
    const [imgFormatError, setImgFormatError] = useState(false);

    // submit button
    const [disableForm, setDisableForm] = useState(false);

    useEffect(() => {
        getMarcas(tokenLayout).then((marcas) => {
            if (marcas !== undefined) {
                let newArr = [];
                marcas.forEach(marca => {
                    newArr.push({ value: marca.id, label: marca.label });
                });
                setOptMarcas(newArr);
            }
        });
    }, []);

    const handleChangeMarcas = (e) => {
        if (e !== null) {
            try {
                setSelMarca(e);
                getModeloByMarca(tokenLayout, e.value).then((modelos) => {
                    if (modelos !== undefined) {

                        let newArr = [];
                        modelos.forEach(modelo => {
                            newArr.push({ value: modelo.modelid, label: modelo.name });
                        });
                        setDisabledModelos(false);
                        setOptModelos(newArr);
                    } else {
                        setDisabledModelos(true);
                    }
                });
            } catch (error) {
                setSelMarca([]);
            }
        } else {
            setDisabledModelos(true);
            setSelMarca(null);
            setSelModelo(null);
        }
    }

    const handleChangeModelos = (e) => {
        if (e !== null) {
            try {
                setSelModelo(e);
            } catch (error) {
                setSelModelo([]);
            }
        } else {
            setSelModelo(null);
        }
    }

    const handleChangeCombustible = (e) => {
        if (e !== null) {
            try {
                setComb(e);
            } catch (error) {
                setComb([]);
            }
        } else {
            setComb(null);
        }
    }

    const handleError = (error) => {
        console.log(error);
        setErrorCam(error);
    }

    const handleChangeCambio = (e) => {
        if (e !== null) {
            try {
                setCamb(e);
            } catch (error) {
                setCamb([]);
            }
        } else {
            setCamb(null);
        }
    }

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
        //setImgBlob(imageSrc);
        //setShow(!show);
    }, [webcamRef]);

    const handleChangeImage = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        let imgData = e.target.files[0];

        new Compressor(imgData, {
            strict: true,
            checkOrientation: true,
            minWidth: 0,
            minHeight: 0,
            height: 720,
            resize: 'none',
            quality: 0.4,
            mimeType: 'auto',
            convertTypes: 'image/jpg,image/jpeg,image/png,image/gif,image/jfif',
            convertSize: 7000,
            success(result) {
                let imgType = result.type;
                let typeSplit = imgType.split('/');
                let imgSize = result.size;

                let imgerror = false;

                if (typeSplit[0] !== 'image') {
                    imgerror = true;
                    setImgFormatError(true);
                }

                if (typeSplit[1] !== 'jpg' && typeSplit[1] !== 'jpeg' && typeSplit[1] !== 'png' && typeSplit[1] !== 'gif' && typeSplit[1] !== 'jfif') {
                    imgerror = true;
                    setImgFormatError(true);
                }

                if (imgSize > 700000) {
                    imgerror = true;
                    setImgSizeError(true);
                }

                if (!imgerror) {
                    setImgFormatError(false);
                    setImgSizeError(false);
                    var file = new File([result], result.name);
                    setImage(file);
                }
            },
            error(err) {
                console.log(err.message);
            },
        });
    }

    useEffect(() => {
        if (image !== null) {
            if (typeof image === 'string') {
                setImgBlob(image);
                setShow(!show);
            } else {
                const objectUrl = URL.createObjectURL(image);
                setImgBlob(objectUrl);
            }
        }
    }, [image]);

    const handleCameraShow = () => {
        setShow(!show);
    }

    // form submit disable
    useEffect(() => {
        if (selMarca !== null && selModelo !== null && fecha !== '' && an !== '' && km !== '' && comb !== null && camb !== null && image !== '' && imgSizeError !== true && imgFormatError !== true) {
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    }, [selMarca, selModelo, fecha, an, km, comb, camb, image, imgSizeError, imgFormatError]);

    const handleSubmit = (e) => {
        e.preventDefault();

        MySwal.fire({
            title: '¿Quieres dar de alta este coche?',
            showCancelButton: true,
            confirmButtonText: 'Dar de alta',
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    const urlApi = getApiUrl();
                    fetch(urlApi + 'coches/alta', {
                        method: 'post',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'X-Api-Key': tokenLayout.api_key,
                        },
                        body: JSON.stringify({
                            marca: selMarca.value,
                            modelo: selModelo.value,
                            mat: mat,
                            fecha: fecha,
                            an: an,
                            km: km,
                            comb: comb.value,
                            camb: camb.value
                        }),
                    }).then((response) => response.json()).then((responseData) => {
                        let lastId = responseData.data[0]['LAST_INSERT_ID()'];
                        const formData = new FormData();
                        formData.append('file', image);
                        formData.set('fileid', lastId);

                        if (typeof image === 'string') {
                            formData.set('isBase64', 1);
                        } else {
                            formData.set('isBase64', 0);
                        }

                        fetch(urlApi + 'uploadimg', {
                            method: 'post',
                            headers: {
                                'X-Api-Key': tokenLayout.api_key,
                            },
                            body: formData,
                        }).then(
                            success => console.log(success)
                        ).catch(
                            error => console.log(error)
                        ).finally(() => {
                            setSelMarca([]);
                            setDisabledModelos(true);
                            setSelModelo([]);
                            setFecha('');
                            setAn('');
                            setKm('');
                            setComb([]);
                            setCamb([]);
                            setImage('');
                            document.getElementById("form-alta").reset();
                            saveToParent(true);
                        });
                    }).catch(error => console.warn(error));
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }
    function MostrarCam() {
        if (show) {
            if (errorCam) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        No se puede utilizar la cámara
                    </div>
                );
            } else {
                const videoConstraints = {
                    facingMode: { exact: "environment" }
                };
                return (
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        onClick={capture}
                        screenshotFormat="image/jpeg"
                        style={{ width: '99%', height: '300px', objectFit: 'cover' }}
                        videoConstraints={videoConstraints}
                        onUserMediaError={(error) => handleError(error)}
                    />
                );
            }
        } else {

            if (imgFormatError) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        Por favor utiliza un formato de imagen válido (.jpg, .jpeg, .png, .gif, .jfif).
                    </div>
                );
            }

            if (imgSizeError) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        El peso de la imagen es demasiado grande.
                    </div>
                );
            }

            if (imgBlob) {
                return (
                    <div>
                        <label>Preview:</label>
                        <div className="d-flex align-items-center justify-content-center">
                            <img style={{ width: '99%', height: '300px', objectFit: 'cover' }} src={imgBlob} alt='captured' />
                        </div>
                    </div>
                );
            } else {
                return (
                    null
                );
            }
        }
    }

    return (
        <>
            <Button variant="success" className="btn btn-sm btn-primary text-white me-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNav" role="button" id="btnTrigger" aria-controls="offcanvasNav">
                <i className="mdi mdi-plus-circle-outline">Dar de alta</i>
            </Button>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNav" data-bs-scroll="true" aria-labelledby="offcanvasNavLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasNavLabel">Dar de alta</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" tabIndex="-1"></button>
                </div>
                <div className="offcanvas-body">
                    <Form id="form-alta" onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-1">
                                    <label>Marcas</label>
                                    <Select options={optMarcas} isClearable='true' onChange={handleChangeMarcas} value={selMarca} />
                                </div>
                                <div className="mb-1">
                                    <label>Modelos</label>
                                    <Select options={optModelos} isDisabled={disabledModelos} isClearable='true' onChange={handleChangeModelos} value={selModelo} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-1">
                                    <label>Matrícula</label>
                                    <input type="text" className="form-control" onChange={(e) => setMatricula(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-1">
                                    <label>Fecha de matriculación</label>
                                    <input type="date" className="form-control" onChange={(e) => setFecha(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-1">
                                    <label>Año</label>
                                    <input type="number" min="1900" max="2099" step="1" className="form-control" onChange={(e) => setAn(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-1">
                                    <label>Km</label>
                                    <input type="number" className="form-control" onChange={(e) => setKm(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-1">
                                    <label>Combustible</label>
                                    <Select options={optCombustible} isClearable='true' onChange={handleChangeCombustible} value={comb} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-1">
                                    <label>Cambio</label>
                                    <Select options={optCambio} isClearable='true' onChange={handleChangeCambio} value={camb} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <label>Imagen</label>
                            <div className="col-10">
                                <div className="mb-1">
                                    <input className="form-control" type="file" id="formFile" accept="image/jpg, image/jpeg, image/png, image/gif, image/jfif" onChange={handleChangeImage} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="mb-1">
                                    <button type='button' className='btn btn-sm btn-primary' onClick={handleCameraShow}>
                                        {show ? (
                                            <CameraOff size={'15px'} />
                                        ) : (
                                            <Camera size={'15px'} />
                                        )}

                                    </button>
                                </div>
                            </div>
                            <MostrarCam />
                        </div>
                        <div className="form-actions">
                            <div className="card-body border-top d-flex align-items-end justify-content-end">
                                <button type="submit" className="btn btn-success rounded px-3" disabled={disableForm} data-bs-dismiss="offcanvas" aria-label="Close" tabIndex="-1">
                                    <div className="d-flex align-items-center">
                                        <i className="mdi mdi-content-save me-2" /> Dar de alta
                                    </div>
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default OffCanvasAlta;