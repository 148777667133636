import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getDespieces, updateLoc, getApiUrl } from '../../Data/data';
import { AlertCircle, Paperclip, Camera, CameraOff, Grid, AlignJustify } from 'react-feather';
import Webcam from "react-webcam";
import Swal from 'sweetalert2';
import DataTableBase from '../../Datatable/Datatable';
import './articulos.css';
import withReactContent from 'sweetalert2-react-content';
import Compressor from 'compressorjs';
const MySwal = withReactContent(Swal);
// columnas para datatables

function setLocArr(arr) {
    let newArr = arr.slice();
    for (let i = 0; i < arr.length; i++) {
        let splitLoc = arr[i].loc.split('.');
        arr[i]["nave"] = splitLoc[0];
        arr[i]["fila"] = splitLoc[1];
        arr[i]["estante"] = splitLoc[2];
        arr[i]["profundidad"] = splitLoc[3];
    }
    return newArr;
}

const Articulos = ({ token, search, filteredData }) => {
    // data original sin filtrar
    const [origData, setOrigData] = useState([]);

    // copia de filtros para no afectar la lista de artículos al utilizar el buscador y filtros a la vez
    const [filterArts, setFilterArts] = useState([]);

    // para mostrar los artículos en datatable y filtraje
    const [articles, setArticles] = useState([]);

    // para mostrar los artículos agrupados por matrícula/coche
    const [artGroup, setArtGroup] = useState([]);

    // muestra el spinner
    const [cLoading, setCLoading] = useState(true);

    // cambio de vista por grid o datatable
    const [view, setView] = useState(false);

    const [editArtId, setEditArtId] = useState(0);

    const [editNave, setEditNave] = useState('');
    const [editFila, setEditFila] = useState('');
    const [editEstante, setEditEstante] = useState('');
    const [editProf, setEditProf] = useState('');

    // selec img
    //const [imgError, setImgError] = useState(false);
    //const [imgsArts, setImgsArts] = useState([]);
    //const [selectedArtImg, setSelectedArtImg] = useState('');

    // image/camera
    const webcamRef = useRef(null);
    const [errorCam, setErrorCam] = useState();
    const [show, setShow] = useState(false);
    const [image, setImage] = useState(null);
    const [imgBlob, setImgBlob] = useState(null);
    const [imgSizeError, setImgSizeError] = useState(false);
    const [imgFormatError, setImgFormatError] = useState(false);

    const editFileRef = useRef(null);
    const editFileRefRow = useRef(null);

    const [editOn, setEditOn] = useState(false);
    const [selEditArt, setSelEditArt] = useState('');

    const [editNaveRow, setEditNaveRow] = useState('');
    const [editFilaRow, setEditFilaRow] = useState('');
    const [editEstanteRow, setEditEstanteRow] = useState('');
    const [editProfRow, setEditProfRow] = useState('');


    const handleButtonClick = (row) => {
        setEditNaveRow(row.nave);
        setEditFilaRow(row.fila);
        setEditEstanteRow(row.estante);
        setEditProfRow(row.profundidad);

        setSelEditArt(row.id);
        setEditOn(true);
    }

    const handleCancelEditRow = () => {
        setEditNaveRow('');
        setEditFilaRow('');
        setEditEstanteRow('');
        setEditProfRow('');
        setSelEditArt('');
        setImage(null);
        setImgBlob(null);
        setShow(false);
        setEditOn(false);

        setImgBlob(null);
        setImage(null);
        setEditArtId(0);
    }

    const handleSaveEditRow = (art) => {
        MySwal.fire({
            title: '¿Quieres guardar los cambios?',
            showCancelButton: true,
            confirmButtonText: 'Guardar',
        }).then((result) => {
            if (result.isConfirmed) {

                // si hay cambios en la localización la guardamos
                let loc = art.loc.split('.');
                if (editNaveRow !== loc[0] || editFilaRow !== loc[1] || editEstanteRow !== loc[2] || editProfRow !== loc[3]) {
                    let newLoc = [];
                    newLoc[0] = editNaveRow ? editNaveRow : loc[0];
                    newLoc[1] = editFilaRow ? editFilaRow : loc[1];
                    newLoc[2] = editEstanteRow ? editEstanteRow : loc[2];
                    newLoc[3] = editProfRow ? editProfRow : loc[3];

                    let newLocStr = newLoc.join('.');
                    articles.forEach(el => {
                        if (el.id === art.id) {
                            el.loc = newLocStr;
                            el.nave = editNaveRow;
                            el.fila = editFilaRow;
                            el.estante = editEstanteRow;
                            el.profundidad = editProfRow;
                        }
                    });

                    artGroup.forEach(coche => {
                        coche.values.forEach(el => {
                            if (el.id === art.id) {
                                el.loc = newLocStr;
                                el.nave = editNaveRow;
                                el.fila = editFilaRow;
                                el.estante = editEstanteRow;
                                el.profundidad = editProfRow;
                            }

                        });
                    });

                    let bodyData = JSON.stringify({
                        idcochedet: art.id,
                        loc: newLocStr
                    });
                    updateLoc(token, bodyData);
                }

                // si hay imagen subida la guardamos
                if (image) {
                    const formData = new FormData();
                    formData.append('file', image);
                    formData.set('idcoche', art.idcoche);
                    formData.set('ref', art.ref);

                    if (typeof imgFile === 'string') {
                        formData.set('isBase64', 1);
                    } else {
                        formData.set('isBase64', 0);
                    }
                    articles.forEach(el => {
                        if (el.id === art.id) {
                            el.imagePath = imgBlob;
                        }
                    });
                    artGroup.forEach(coche => {
                        coche.values.forEach(el => {
                            if (el.id === art.id) {
                                el.imagePath = imgBlob;
                            }

                        });
                    });
                    const urlApi = getApiUrl();
                    fetch(urlApi + 'uploadArtImg', {
                        method: 'post',
                        headers: {
                            'X-Api-Key': token.api_key,
                        },
                        body: formData,
                    }).then(
                        success => console.log(success)
                    ).catch(
                        error => console.log(error)
                    ).finally(() => {
                        setImgBlob(null);
                        setImage(null);
                    });
                }

                // reset del edit
                setEditNaveRow('');
                setEditFilaRow('');
                setEditEstanteRow('');
                setEditProfRow('');
                setSelEditArt('');
                setImage(null);
                setImgBlob(null);
                setShow(false);
                setEditOn(false);
            }
        });

    }

    const handleNaveEditRow = (e) => {
        setEditNaveRow(e);
    }

    const handleFilaEditRow = (e) => {
        setEditFilaRow(e);
    }

    const handleEstanteEditRow = (e) => {
        setEditEstanteRow(e);
    }

    const handleProfundidadEditRow = (e) => {
        setEditProfRow(e);
    }

    useEffect(() => {
        if (image !== null) {
            if (typeof image === 'string') {
                setImgBlob(image);
            } else {
                const objectUrl = URL.createObjectURL(image);
                setImgBlob(objectUrl);
            }
        }
    }, [image]);

    const onFileEditChangeRow = (e) => {

        if (!e.target.files[0]) {
            return;
        }

        let imgData = e.target.files[0];
        let imgType = e.target.files[0].type;
        let typeSplit = imgType.split('/');

        if (typeSplit[1] !== 'jpg' && typeSplit[1] !== 'jpeg' && typeSplit[1] !== 'png' && typeSplit[1] !== 'gif' && typeSplit[1] !== 'jfif') {
            setImgFormatError(true);
        } else {
            new Compressor(imgData, {
                strict: true,
                checkOrientation: true,
                minWidth: 0,
                minHeight: 0,
                height: 720,
                resize: 'none',
                quality: 0.4,
                mimeType: 'auto',
                convertTypes: 'image/jpg,image/jpeg,image/png,image/gif,image/jfif',
                convertSize: 7000,
                success(result) {
                    let resultType = result.type;
                    let resultTypeSplit = resultType.split('/');
                    let imgSize = result.size;

                    let imgerror = false;

                    if (resultTypeSplit[0] !== 'image') {
                        imgerror = true;
                        setImgFormatError(true);
                    }

                    if (resultTypeSplit[1] !== 'jpg' && resultTypeSplit[1] !== 'jpeg' && resultTypeSplit[1] !== 'png' && resultTypeSplit[1] !== 'gif' && resultTypeSplit[1] !== 'jfif') {
                        imgerror = true;
                        setImgFormatError(true);
                    }

                    if (imgSize > 700000) {
                        imgerror = true;
                        setImgSizeError(true);
                    }

                    if (!imgerror) {
                        setImgFormatError(false);
                        setImgSizeError(false);
                        var file = new File([result], result.name);
                        setImage(file);
                    }
                },
                error(err) {
                    console.log(err.message);
                },
            });
        }
    }

    const handleChangeImageRow = (e) => {
        editFileRefRow.current.click();
    }

    const handleCameraShowRow = () => {
        setShow(!show);
    }

    function imgRow(row) {
        if (row.imagePath) {
            return (
                <img src={row.imagePath} alt='imagen de artículo' className='rounded' style={{ height: '40px', width: '40px', objectFit: 'cover' }}></img>
            );
        } else {
            return (
                <div className="bg-light-danger text-danger d-inline-block py-3 rounded d-flex align-items-center justify-content-center me-2" style={{ width: '40px', height: '40px' }}>
                    <AlertCircle size={'15px'} />
                </div>
            );
        }
    }

    function editImgRow(row) {
        if (selEditArt === row.id) {
            return (
                <div>
                    { /* Botón seleccionar imagen */}
                    <input key={row.id} ref={editFileRefRow} onChange={(e) => onFileEditChangeRow(e)} type="file" id="formFile" accept="image/jpg, image/jpeg, image/png, image/gif, image/jfif" style={{ display: 'none' }} />
                    <button value={row.id} className="btn btn-sm btn-warning me-1 mt-1" type="button" onClick={(e) => handleChangeImageRow(e)} ><Paperclip color="white" size={12} /></button>

                    { /* Botón cámara */}
                    <button value={row.id} className="btn btn-primary btn-sm me-1 mt-1" type="button" onClick={() => handleCameraShowRow()} >
                        {show ? (
                            <CameraOff size={'12px'} />
                        ) : (
                            <Camera size={'12px'} />
                        )}
                    </button>

                    <MostrarCam />
                </div>
            );
        } else {
            return (
                null
            );
        }

    }

    const columns = [
        {
            name: 'Editar',
            maxWidth: '85px',
            minWidth: '85px',
            cell: (row) => {
                if (editOn) {
                    if (selEditArt === row.id) {
                        return (
                            <div>
                                <button className="btn btn-sm btn-info me-1" onClick={() => handleSaveEditRow(row)} style={{ width: "30px" }}><i className="fas fa-save"></i></button>
                                <button className="btn btn-sm btn-danger" onClick={() => handleCancelEditRow()} style={{ width: "30px" }}>X</button>
                            </div>
                        );
                    }
                } else {
                    return <button className="btn btn-sm btn-warning" onClick={() => handleButtonClick(row)} style={{ width: "30px" }}><i className="fas fa-edit"></i></button>
                }
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Imagen',
            selector: row => row.imagePath,
            maxWidth: '200px',
            minWidth: '185px',
            width: 'auto',
            cell: (row) => {
                if (row.imagePath !== '') {
                    return (
                        <div className="d-flex flex-column my-1">
                            {imgRow(row)}
                            {editImgRow(row)}
                        </div>

                    );
                } else {
                    return (
                        <div className="d-flex flex-column my-1">
                            {imgRow(row)}
                            {editImgRow(row)}
                        </div>
                    );
                }
            },
        },
        {
            name: 'Matrícula',
            selector: row => row.matricula,
            sortable: true,
        },
        {
            name: 'Familia',
            selector: row => row.familia,
            sortable: true,
            maxWidth: '300px',
            minWidth: '300px',
        },
        {
            name: 'Artículo',
            selector: row => row.articulo,
            sortable: true,
        },
        {
            name: 'Nave',
            selector: row => row.nave,
            sortable: true,
            maxWidth: '85px',
            minWidth: '85px',
            cell: (row) => editOn && selEditArt === row.id ? (
                <div>
                    <input style={{ width: '40px' }} type={"text"} onChange={(e) => handleNaveEditRow(e.target.value)} value={editNaveRow} />
                </div>
            ) : (
                <div>
                    {row.nave}
                </div>
            )
        },
        {
            name: 'Fila',
            selector: row => row.fila,
            sortable: true,
            maxWidth: '85px',
            minWidth: '85px',
            cell: (row) => editOn && selEditArt === row.id ? (
                <div>
                    <input style={{ width: '40px' }} type={"text"} onChange={(e) => handleFilaEditRow(e.target.value)} value={editFilaRow} />
                </div>
            ) : (
                <div>
                    {row.fila}
                </div>
            )
        },
        {
            name: 'Estante',
            selector: row => row.estante,
            sortable: true,
            maxWidth: '85px',
            minWidth: '85px',
            cell: (row) => editOn && selEditArt === row.id ? (
                <div>
                    <input style={{ width: '40px' }} type={"text"} onChange={(e) => handleEstanteEditRow(e.target.value)} value={editEstanteRow} />
                </div>
            ) : (
                <div>
                    {row.estante}
                </div>
            )
        },
        {
            name: 'Profundidad',
            selector: row => row.profundidad,
            sortable: true,
            maxWidth: '85px',
            minWidth: '85px',
            cell: (row) => editOn && selEditArt === row.id ? (
                <div>
                    <input style={{ width: '40px' }} type={"text"} onChange={(e) => handleProfundidadEditRow(e.target.value)} value={editProfRow} />
                </div>
            ) : (
                <div>
                    {row.profundidad}
                </div>
            )
        },
    ];

    // fetch de artículos
    useEffect(() => {
        if (!token) {
            return;
        }
        document.title = 'Desguaces - Artículos';
        getDespieces(token).then((art) => {
            if (art !== undefined) {
                let newArt = setLocArr(art);
                setCLoading(true);
                setOrigData(newArt);
                setArticles(newArt);
            }
        }).finally(() => {
            setCLoading(false);
        });
    }, [token]);

    // cuando hayan cambios en artículos, agrupamos los artículos por cada coche
    useEffect(() => {
        if (articles.length > 0) {
            let groupByArray = function (xs, key) {
                let c = 0;
                return xs.reduce(function (rv, x) {
                    let v = key instanceof Function ? key(x) : x[key]; let el = rv.find((r) => r && r.key === v);
                    if (el) {
                        el.values.push(x);
                    } else {
                        rv.push({ id: c, key: v, values: [x] });
                        c++;
                    } return rv;
                }, []);
            }
            let grouped = groupByArray(articles, 'matricula');
            setArtGroup(grouped);
        } else {
            setArtGroup([]);
        }
    }, [articles]);

    // cuando hayan cambios en el buscador, se construye una string con todos los datos posibles a buscar
    // por otro lado, se separa cada palabra escrita en el buscador y se forma un regex
    // se compara cada regex con las strings de los artículos y solo nos quedamos con los artículos que coincidan
    useEffect(() => {
        if (search !== '') {
            let strSearch = search.toLowerCase();
            strSearch = strSearch.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
            let splitStr = strSearch.split(' ');
            let regexStr = '';
            for (let i = 0; i < splitStr.length; i++) {
                regexStr += "(?=.*" + splitStr[i] + ")";
            }
            regexStr += '.+';
            let re = new RegExp(regexStr, 'g');
            let arrMatches = [];

            if (filterArts.length > 0) {
                filterArts.forEach(art => {
                    if (art.articulo !== null) {
                        let str = '';
                        try {
                            str = art.articulo.toLowerCase() + " " + art.familia.toLowerCase() + " " + art.matricula.toLowerCase() + " " + art.marcalabel.toLowerCase() + " " + art.modelname.toLowerCase() + " " + art.an.toLowerCase() + " " + art.nave.toLowerCase() + "." + art.fila.toLowerCase() + "." + art.estante.toLowerCase() + "." + art.profundidad.toLowerCase();
                        } catch (e) {
                            console.log(e);
                        }
                        while (!!(re.exec(str))) {
                            arrMatches.push(art);
                        }
                    }
                });
                setArticles(arrMatches);
            } else {
                origData.forEach(art => {
                    if (art.articulo !== null) {
                        let str = '';
                        try {
                            str = art.articulo.toLowerCase() + " " + art.familia.toLowerCase() + " " + art.matricula.toLowerCase() + " " + art.marcalabel.toLowerCase() + " " + art.modelname.toLowerCase() + " " + art.an.toLowerCase() + " " + art.nave.toLowerCase() + "." + art.fila.toLowerCase() + "." + art.estante.toLowerCase() + "." + art.profundidad.toLowerCase();
                        } catch (e) {
                            console.log(e);
                        }
                        while (!!(re.exec(str))) {
                            arrMatches.push(art);
                        }
                    }
                });
                setArticles(arrMatches);
            }
        } else {
            if (filterArts.length > 0) {
                setArticles(filterArts);
            } else {
                setArticles(origData);
            }
        }
    }, [search, filterArts]);

    // cuando hayan cambios en filtros se aplican en artículos
    useEffect(() => {
        let newArr = setLocArr(filteredData);
        setFilterArts(newArr);
        setArticles(newArr);
    }, [filteredData]);

    // obtenemos la localización (x.x.x.x) y la separamos
    function parseLoc(loc) {
        let splitLoc = loc.split('.');
        return (
            <div className="d-flex align-items-center mt-1">
                <div className="border-end pe-3">
                    <h6 className="text-muted fw-normal">Nave</h6>
                    <b>{splitLoc[0]}</b>
                </div>
                <div className="ms-3 border-end pe-3">
                    <h6 className="text-muted fw-normal">Fila</h6>
                    <b>{splitLoc[1]}</b>
                </div>
                <div className="ms-3">
                    <h6 className="text-muted fw-normal">Estante</h6>
                    <b>{splitLoc[2]}</b>
                </div>
                <div className="ms-3">
                    <h6 className="text-muted fw-normal">Profundidad</h6>
                    <b>{splitLoc[3]}</b>
                </div>
            </div>
        );
    }


    // edit loc
    const handleChangeNave = (e) => {
        setEditNave(e.currentTarget.value);
    }

    const handleChangeFilas = (e) => {
        setEditFila(e.currentTarget.value);
    }

    const handleChangeEstantes = (e) => {
        setEditEstante(e.currentTarget.value);
    }

    const handleChangeProfs = (e) => {
        setEditProf(e.currentTarget.value);
    }

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
    }, [webcamRef]);


    const handleChangeImage = (e) => {
        //setSelectedEditImg(e.currentTarget.value);
        editFileRef.current.click();
    }

    // al seleccionar un archivo en artículos ya guardados
    const onFileEditChange = (e) => {

        if (!e.target.files[0]) {
            return;
        }

        let imgData = e.target.files[0];
        let imgType = e.target.files[0].type;
        let typeSplit = imgType.split('/');

        if (typeSplit[1] !== 'jpg' && typeSplit[1] !== 'jpeg' && typeSplit[1] !== 'png' && typeSplit[1] !== 'gif' && typeSplit[1] !== 'jfif') {
            setImgFormatError(true);
        } else {
            new Compressor(imgData, {
                strict: true,
                checkOrientation: true,
                minWidth: 0,
                minHeight: 0,
                height: 720,
                resize: 'none',
                quality: 0.4,
                mimeType: 'auto',
                convertTypes: 'image/jpg,image/jpeg,image/png,image/gif,image/jfif',
                convertSize: 7000,
                success(result) {
                    let resultType = result.type;
                    let resultTypeSplit = resultType.split('/');
                    let imgSize = result.size;

                    let imgerror = false;

                    if (resultTypeSplit[0] !== 'image') {
                        imgerror = true;
                        setImgFormatError(true);
                    }

                    if (resultTypeSplit[1] !== 'jpg' && resultTypeSplit[1] !== 'jpeg' && resultTypeSplit[1] !== 'png' && resultTypeSplit[1] !== 'gif' && resultTypeSplit[1] !== 'jfif') {
                        imgerror = true;
                        setImgFormatError(true);
                    }

                    if (imgSize > 700000) {
                        imgerror = true;
                        setImgSizeError(true);
                    }

                    if (!imgerror) {
                        setImgFormatError(false);
                        setImgSizeError(false);
                        var file = new File([result], result.name);
                        setImage(file);
                    }
                },
                error(err) {
                    console.log(err.message);
                },
            });
        }
    }

    useEffect(() => {
        if (image !== null) {
            if (typeof image === 'string') {
                setImgBlob(image);
                setShow(!show);
            } else {
                const objectUrl = URL.createObjectURL(image);
                setImgBlob(objectUrl);
            }
        }
    }, [image]);

    const handleCameraShow = () => {
        setShow(!show);
    }



    // para cambiar la vista entre grid y datatable
    const handleView = () => {
        setView(!view);
    }

    const handleEditArt = (art) => {
        setImage(null);
        setImgBlob(null);
        setEditArtId(art.id);

        let splitLoc = art.loc.split('.');
        setEditNave(splitLoc[0]);
        setEditFila(splitLoc[1]);
        setEditEstante(splitLoc[2]);
        setEditProf(splitLoc[3]);
    }

    const handleCancelEditArt = () => {
        setImgBlob(null);
        setImage(null);
        setEditArtId(0);
    }

    const handleSaveEditArt = (art) => {
        MySwal.fire({
            title: '¿Quieres guardar los cambios?',
            showCancelButton: true,
            confirmButtonText: 'Guardar',
        }).then((result) => {
            if (result.isConfirmed) {
                // si hay cambios en la localización la guardamos
                let loc = art.loc.split('.');
                if (editNave !== loc[0] || editFila !== loc[1] || editEstante !== loc[2] || editProf !== loc[3]) {
                    let newLoc = [];
                    newLoc[0] = editNave ? editNave : loc[0];
                    newLoc[1] = editFila ? editFila : loc[1];
                    newLoc[2] = editEstante ? editEstante : loc[2];
                    newLoc[3] = editProf ? editProf : loc[3];

                    let newLocStr = newLoc.join('.');
                    articles.forEach(el => {
                        if (el.id === art.id) {
                            el.loc = newLocStr;
                        }
                    });

                    artGroup.forEach(coche => {
                        coche.values.forEach(el => {
                            if (el.id === art.id) {
                                el.loc = newLocStr;
                            }

                        });
                    });
                    let bodyData = JSON.stringify({
                        idcochedet: art.id,
                        loc: newLocStr
                    });
                    updateLoc(token, bodyData);
                }

                // si hay imagen subida la guardamos
                if (image) {
                    const formData = new FormData();
                    formData.append('file', image);
                    formData.set('idcoche', art.idcoche);
                    formData.set('ref', art.ref);

                    if (typeof imgFile === 'string') {
                        formData.set('isBase64', 1);
                    } else {
                        formData.set('isBase64', 0);
                    }
                    articles.forEach(el => {
                        if (el.id === art.id) {
                            el.imagePath = imgBlob;
                        }
                    });
                    artGroup.forEach(coche => {
                        coche.values.forEach(el => {
                            if (el.id === art.id) {
                                el.imagePath = imgBlob;
                            }

                        });
                    });
                    const urlApi = getApiUrl();
                    fetch(urlApi + 'uploadArtImg', {
                        method: 'post',
                        headers: {
                            'X-Api-Key': token.api_key,
                        },
                        body: formData,
                    }).then(
                        success => console.log(success)
                    ).catch(
                        error => console.log(error)
                    ).finally(() => {
                        setImgBlob(null);
                        setImage(null);
                    });
                }

                setArticles(articles);
                setArtGroup(artGroup);

                // reset del edit
                setEditNave('');
                setEditFila('');
                setEditEstante('');
                setEditProf('');
                setEditArtId(0);
            }
        });
    }


    const handleError = (error) => {
        setErrorCam(error);
    }

    function MostrarCam() {
        if (show) {
            if (errorCam) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        No se puede utilizar la cámara
                    </div>
                );
            } else {
                const videoConstraints = {
                    facingMode: { exact: "environment" }
                };
                return (
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        onClick={capture}
                        screenshotFormat="image/jpeg"
                        style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                        videoConstraints={videoConstraints}
                        onUserMediaError={(error) => handleError(error)}
                    />
                );
            }
        } else {

            if (imgFormatError) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        Por favor utiliza un formato de imagen válido (.jpg, .jpeg, .png, .gif, .jfif).
                    </div>
                );
            }

            if (imgSizeError) {
                return (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        El peso de la imagen es demasiado grande.
                    </div>
                );
            }

            if (imgBlob) {
                return (
                    <div>
                        <label>Preview:</label>
                        <div className="d-flex align-items-center justify-content-center">
                            <img style={{ width: '200px', height: '200px', objectFit: 'cover' }} src={imgBlob} alt='captured' />
                        </div>
                    </div>
                );
            } else {
                return (
                    null
                );
            }
        }
    }

    // doble .map para navegar primero por las matrículas y luego por los artículos
    const ArtList = () => {
        const listArt = artGroup.map((coche) =>
            <div key={coche.id}>
                <h3 className="text-info">{coche.key}</h3>
                <div className="row">
                    {coche.values.map(art =>
                        <div key={art.id} className="col-md-6 col-lg-4 col-xl-3">
                            <div className="card rounded-3">
                                {
                                    editArtId === art.id ?
                                        (
                                            <div>
                                                <div className="card-body">
                                                    <div className="d-flex flex-row">
                                                        {
                                                            art.imagePath ?
                                                                (
                                                                    <div style={{ flexShrink: '0' }}>
                                                                        <img alt='imagen de artículo' src={art.imagePath} className="rounded img-fluid me-2" style={{ height: '50px', width: '50px', objectFit: 'cover' }} />
                                                                    </div>
                                                                ) : (
                                                                    <div className="bg-light-danger text-danger d-inline-block py-3 rounded d-flex align-items-center justify-content-center me-2" style={{ flexShrink: '0', width: '50px' }}>
                                                                        <AlertCircle size={'20px'} />
                                                                    </div>
                                                                )
                                                        }
                                                        <div>
                                                            <h6 className="font-weight-medium mb-1">{art.articulo}</h6>
                                                            <h6 className="card-subtitle mb-0 fs-2 fw-normal">{art.familia}</h6>
                                                        </div>
                                                    </div>

                                                    <form className="d-flex">
                                                        <div className='col-3'>
                                                            <h6 className="text-muted fw-normal">Nave</h6>
                                                            <input type="text" className="form-control" placeholder="nave" value={editNave} onChange={(e) => handleChangeNave(e)} />
                                                        </div>
                                                        <div className='col-3'>
                                                            <h6 className="text-muted fw-normal">Fila</h6>
                                                            <input type="text" className="form-control" placeholder="fila" value={editFila} onChange={(e) => handleChangeFilas(e)} />
                                                        </div>
                                                        <div className='col-3'>
                                                            <h6 className="text-muted fw-normal">Estante</h6>
                                                            <input type="text" className="form-control" placeholder="estante" value={editEstante} onChange={(e) => handleChangeEstantes(e)} />
                                                        </div>
                                                        <div className='col-3'>
                                                            <h6 className="text-muted fw-normal">Prof.</h6>
                                                            <input type="text" className="form-control" placeholder="profundidad" value={editProf} onChange={(e) => handleChangeProfs(e)} />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="card-body">
                                                    <div className="d-flex flex-row">
                                                        {
                                                            art.imagePath ?
                                                                (
                                                                    <div style={{ flexShrink: '0' }}>
                                                                        <img alt='imagen de artículo' src={art.imagePath} className="rounded img-fluid me-2" style={{ height: '50px', width: '50px', objectFit: 'cover' }} />
                                                                    </div>
                                                                ) : (
                                                                    <div className="bg-light-danger text-danger d-inline-block py-3 rounded d-flex align-items-center justify-content-center me-2" style={{ flexShrink: '0', width: '50px' }}>
                                                                        <AlertCircle size={'20px'} />
                                                                    </div>
                                                                )
                                                        }
                                                        <div>
                                                            <h6 className="font-weight-medium mb-1">{art.articulo}</h6>
                                                            <h6 className="card-subtitle mb-0 fs-2 fw-normal">{art.familia}</h6>
                                                        </div>
                                                    </div>
                                                    <span className="fs-2 mt-1 font-weight-medium">{parseLoc(art.loc)}</span>
                                                </div>
                                            </div>
                                        )
                                }

                                <div className="card-footer bg-light-warning">
                                    {
                                        editArtId === art.id ?
                                            (
                                                <div>
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            { /* Botón seleccionar imagen */}
                                                            <input key={art.id} ref={editFileRef} onChange={(e) => onFileEditChange(e)} type="file" id="formFile" accept="image/jpg, image/jpeg, image/png, image/gif, image/jfif" style={{ display: 'none' }} />
                                                            <button value={art.id} className="btn btn-warning btn-sm me-1 mt-1" type="button" onClick={(e) => handleChangeImage(e)} ><Paperclip color="white" size={15} /></button>

                                                            { /* Botón cámara */}
                                                            <button value={art.id} className="btn btn-primary btn-sm me-1 mt-1" type="button" onClick={() => handleCameraShow()} >
                                                                {show ? (
                                                                    <CameraOff size={'15px'} />
                                                                ) : (
                                                                    <Camera size={'15px'} />
                                                                )}
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button className="btn btn-sm btn-success me-2" onClick={() => handleSaveEditArt(art)}><i className="fas fa-check"></i></button>
                                                            <button className="btn btn-sm btn-danger" onClick={() => handleCancelEditArt()}>X</button>
                                                        </div>
                                                    </div>
                                                    <MostrarCam />
                                                </div>


                                            ) : (
                                                <div>
                                                    <button className="btn btn-sm btn-warning" onClick={() => handleEditArt(art)}><i className="fas fa-edit"></i></button>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <hr className="my-4" />
            </div>
        );

        /*
        // listado sin agrupar
        const listArt = articles.map((art) =>
            <div key={art.id} className="col-md-6 col-lg-4 col-xl-3">
                <div className="card rounded-3 card-hover">
                    <a href="#" className="stretched-link"></a>
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="">
                                <h4 className="card-title text-dark">{art.matricula ? art.matricula : 'Sin matrícula'}</h4>
                                <h6 className="card-subtitle mb-0 fs-2 fw-normal">{art.familia}</h6>
                                <h6 className="card-subtitle mb-0 fs-2 fw-normal mt-1">{art.articulo}</h6>
                                <span className="fs-2 mt-1 font-weight-medium">{parseLoc(art.loc)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        */

        // spinner al cargar artículos
        function Loader() {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        // si al buscar o filtrar no hay artículos encontrados
        function NoElements() {
            return (
                <div className="col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-row">
                                <div className="ms-2 align-self-center">
                                    <h3 className="mb-0">Sin resultados</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        // si está cargando, muestra el spinner
        if (!cLoading) {

            // si no hay artículos es que no se ha encontrado ninguno con los filtros/buscador
            if (articles.length > 0) {

                // vista en grid o datatable
                if (!view) {
                    return (
                        <div className="row" >
                            <div className="d-flex justify-content-end mb-2 align-items-center">
                                <button className="btn btn-sm btn-secondary" onClick={handleView}><AlignJustify></AlignJustify></button>
                            </div>
                            {listArt}
                        </div>
                    );
                } else {
                    return (
                        <div className="row" >
                            <div className="d-flex justify-content-end mb-2 align-items-center">
                                <button className="btn btn-sm btn-secondary" onClick={handleView}><Grid></Grid></button>
                            </div>
                            <DataTableBase
                                columns={columns}
                                data={articles}
                            />
                        </div>
                    );
                }
            } else {
                return (
                    <NoElements />
                );
            }
        } else {
            return (
                <Loader />
            );
        }
    }

    return (
        <ArtList />
    );
}

export default Articulos;