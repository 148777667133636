import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({ path }) => {
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        setCurrentPath(path.pathname);
    }, [path]);

    if (currentPath === '/' || currentPath === '/coches') {
        return (
            <div className="col-md-3 col-12 align-self-center">
                <h3 className="text-themecolor mb-0">Coche</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to={'/'}>Coches</Link></li>
                </ol>
            </div>
        );
    } else if (currentPath === '/articulos') {
        return (
            <div className="col-md-3 col-12 align-self-center">
                <h3 className="text-themecolor mb-0">Artículos</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to={'/articulos'}>Artículos</Link></li>
                </ol>
            </div>
        );
    } else {
        return (
            <div className="col-md-3 col-12 align-self-center">
                <h3 className="text-themecolor mb-0">Api doc</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to={'/apidoc'}>Api doc</Link></li>
                </ol>
            </div>
        );
    }
}

export default BreadCrumbs;